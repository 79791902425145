import { useEffect, useState } from 'react';
import { DataSyncStatus } from '../../../domain/Crm/DataSync';
import { ImportStatus } from '../../../domain/Records/Import';

export type GROUP_DATA_STATE = 'defaultState' | 'successState' | 'errorState';

export const useSyncDataAnimationPropertyStateMapper = (
    dataSyncStatus: DataSyncStatus,
    importStatus: ImportStatus | null,
    importErrorsRetrieved: boolean
) => {
    const ANIMATION_REFRESH_INTERVAL = 600;

    const getBall1State = () => {
        if (dataSyncStatus === 'NOT_STARTED' || dataSyncStatus === 'VALIDATING') {
            return 'RUNNING';
        } else {
            return dataSyncStatus;
        }
    };

    let ball1State = getBall1State();

    const getBall2State = () => {
        if (importStatus === 'SUCCESS') {
            if (importErrorsRetrieved) {
                return 'SUCCESS';
            } else {
                return 'RUNNING';
            }
        } else if (importStatus) {
            if (importStatus === 'NOT_STARTED' && dataSyncStatus === 'FINISHED') {
                return 'RUNNING';
            } else {
                return importStatus;
            }
        } else {
            if (dataSyncStatus !== 'FINISHED') {
                return 'NOT_STARTED';
            } else {
                return 'RUNNING';
            }
        }
    };
    const ball2State = getBall2State();

    let groupDataSyncArrowState: GROUP_DATA_STATE;
    if (dataSyncStatus === 'FINISHED') {
        groupDataSyncArrowState = 'successState';
    } else if (dataSyncStatus === 'ERROR') {
        groupDataSyncArrowState = 'errorState';
    } else {
        groupDataSyncArrowState = 'defaultState';
    }

    let groupImportArrowState: GROUP_DATA_STATE;
    if (importStatus === 'SUCCESS' && importErrorsRetrieved) {
        groupImportArrowState = 'successState';
    } else if (importStatus === 'FAILED') {
        groupImportArrowState = 'errorState';
    } else {
        groupImportArrowState = 'defaultState';
    }

    const opacityEnabledGroupImportArrow = dataSyncStatus === 'FINISHED';

    const [dataSyncArrowVisibility, setDataSyncArrowVisibility] = useState(true);
    const [importArrowVisibility, setImportArrowVisibility] = useState(true);

    useEffect(() => {
        let blinkingInterval: NodeJS.Timer;

        if (dataSyncStatus === 'RUNNING' || dataSyncStatus === 'NOT_STARTED' || dataSyncStatus === 'VALIDATING') {
            blinkingInterval = setInterval(
                () => setDataSyncArrowVisibility((prevVisibility) => !prevVisibility),
                ANIMATION_REFRESH_INTERVAL
            );
        } else if (
            importStatus === null ||
            importStatus === 'RUNNING' ||
            importStatus === 'NOT_STARTED' ||
            (importStatus === 'SUCCESS' && !importErrorsRetrieved)
        ) {
            blinkingInterval = setInterval(
                () => setImportArrowVisibility((prevVisibility) => !prevVisibility),
                ANIMATION_REFRESH_INTERVAL
            );
        }

        return () => {
            if (blinkingInterval) {
                clearInterval(blinkingInterval);
                setDataSyncArrowVisibility(true);
                setImportArrowVisibility(true);
            }
        };
    }, [dataSyncStatus, importStatus, importErrorsRetrieved]);

    return {
        dataSyncArrowVisibility,
        importArrowVisibility,
        opacityEnabledGroupImportArrow,
        groupDataSyncArrowState,
        groupImportArrowState,
        ball1State,
        ball2State,
    };
};
