type ValidationError = {
    email?: string[];
    username?: string[];
};

export enum UserCreationStatus {
    'SUCCEEDED',
    'FAILED',
}

type UserCreationResult = {
    errors?: ValidationError;
    status: UserCreationStatus;
    userId: number;
};

export type { UserCreationResult, ValidationError };
