import { StaffDTO } from '../repositories/AvailableStaffRepository';

export const AvailableStaffDtoMapper = () => {
    const map = (responseBody: any): StaffDTO[] => {
        return responseBody.items.map((item: any) => {
            return {
                id: item.id.toString(), // BMG-956 when contract changed, no casting needed
                first_name: item.first_name,
                last_name: item.last_name,
                username: item.username,
                email: item.email,
                last_log_in: item.last_log_in,
                date_joined: item.date_joined,
                state: item.state,
            };
        });
    };

    return { map };
};
