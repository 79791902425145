import { FieldType, PickListOption } from '../../domain/CompanyDefinition/CompanyDefinition';

export const ROW_COLUMN_SEPARATOR = '//';

export const enum ColumnType {
    NUMBER = 'number',
    TEXT = 'text',
    BOOLEAN = 'boolean',
    SORTING_ORDER = 'sorting_order',
    PICKLIST_LABELS_VALUES = 'pick_list_labels_values',
    PICKLIST = 'pick_list',
    ACTION = 'action',
    ORDER = 'order',
}

export const PicklistFieldOptions = [
    { label: 'Text', value: 'text', uuid: 'text' },
    { label: 'Number', value: 'numeric', uuid: 'numeric' },
    { label: 'Picklist', value: 'picklist', uuid: 'picklist' },
    { label: 'Address', value: 'address', uuid: 'address' },
    { label: 'Date/Time', value: 'datetime', uuid: 'datetime' },
];

export const DisplayTypeOptions = [
    { label: 'TITLE', value: 'AS_TITLE' },
    { label: 'SUBTITLE', value: 'AS_SUBTITLE' },
    { label: 'DETAIL', value: 'AS_DETAIL' },
];

export const PicklistOptions: { [key: string]: any[] } = {
    displayType: DisplayTypeOptions,
    type: PicklistFieldOptions,
};

export const enum SortingOrderType {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
    UNDEFINED = '',
}

export type Row = {
    id: string | null;
    uuid: string;
    displayOrder: number;
    label: string;
    name: string;
    type: string;
    isMandatory: boolean;
    sortingOrder: SortingOrderType;
    isMain: boolean;
    isSearchable: boolean;
    isColorizable: boolean | null;
    isFilterable: boolean | null;
    isTextArea: boolean | null;
    isViewOnly: boolean;
    displayType: string;
    pickList: PickListOption[] | null;
    decimals: number | null;
};

export type Field = {
    uuid: string;
    name: string;
    label: string;
    type: FieldType;
};
