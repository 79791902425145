import Box from '@mui/material/Box';
import styled from 'styled-components';
import Logo from '../common/components/MainMenu/drupe_logo.svg';
import { useEffect } from 'react';
import useShowErrorBoundaryOnPromiseRejections from '../common/hooks/useShowErrorBoundaryOnPromiseRejections';
import { useLoginController } from './useLoginController';

const StyledRootContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.Background.menu.default};
`;

const StyledLoginContainer = styled(Box)`
    width: 245px;
    height: 310px;
`;

const StyledLogoContainer = styled(Box)`
    display: flex;
    justify-content: center;
`;

const StyledLoginInput = styled('input')`
    width: 100%;
    display: block;
    margin: 10px 0 0 0;
    border: 0;
    background: 0;
    padding: 11px 10px 9px 10px;
    border-radius: 0;
    background: #fff;
`;

const StyledLoginInputContainer = styled('form')`
    position: relative;
    margin-bottom: 10px;
    padding: 0;
    overflow: hidden;
    border-radius: 4px;
    margin-top: 20px;
`;

const StyledLoginButton = styled('button')`
    background: #4a90e2;
    color: #fff;
    border: 1px solid #3d82d3;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 35px;
`;

const StyledErrorMessage = styled('p')`
    color: red;
`;

const Login = ({ useController }: { useController: typeof useLoginController }) => {
    useShowErrorBoundaryOnPromiseRejections();
    const { setUsername, setPassword, getToken, userAuth, navigate } = useController();

    useEffect(() => {
        if (userAuth.token) {
            navigate('/companies');
        }
    }, [userAuth.token, navigate]);

    return (
        <StyledRootContainer>
            <StyledLoginContainer>
                <StyledLogoContainer>
                    <Box component="img" alt="Drupe logo." src={Logo} />
                </StyledLogoContainer>
                <StyledLoginInputContainer
                    className="input-container"
                    onSubmit={(e) => {
                        e.preventDefault();
                        void getToken();
                    }}
                >
                    <StyledLoginInput
                        type="text"
                        autoCapitalize="off"
                        autoCorrect="off"
                        required
                        data-testid="username-input"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Email address or Username"
                    />
                    <StyledLoginInput
                        type="password"
                        placeholder="Password"
                        required
                        data-testid="password-input"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input type="submit" hidden />
                </StyledLoginInputContainer>
                {userAuth.error && <StyledErrorMessage>{userAuth.error}</StyledErrorMessage>}
                <StyledLoginButton data-testid="login-button" onClick={() => getToken()}>
                    Log in
                </StyledLoginButton>
            </StyledLoginContainer>
        </StyledRootContainer>
    );
};
export default Login;
