import { StaffDTO } from '../repositories/AvailableStaffRepository';
import useUserAuth from '../../common/hooks/useUserAuth';
import { HttpClient, HttpMethod } from '../../common/api/HttpClient';
import { AvailableStaffDtoMapper } from './AvailableStaffDtoMapper';

export const AvailableStaffDataSource = () => {
    const { userAuth } = useUserAuth();
    const { fetch } = HttpClient();
    const { map } = AvailableStaffDtoMapper();

    const getAvailableStaff = async (): Promise<StaffDTO[]> => {
        const response = await fetch(HttpMethod.GET, '/api/3/users/staff', userAuth.token);
        if (response.status === 200) {
            return Promise.resolve(map(response.body));
        }
        return Promise.reject(response.status);
    };
    return { getAvailableStaff };
};
