import { styled } from 'styled-components';
import { Button, BUTTON_SEVERITY_LEVELS, ButtonTypes, Icon, IconNames, loadFontAttributes } from 'design-system';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Control, ControllerRenderProps, FieldValues, RegisterOptions } from 'react-hook-form/dist/types';
import { PickListOption } from '../../../domain/CompanyDefinition/CompanyDefinition';

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Container = styled(Column)`
    width: 500px;
    min-height: 100px;
    max-height: 300px;
    padding: 20px 0;
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
`;

const Title = styled.span`
    margin: 0;
    padding: 0 30px;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.bold)};
`;

const LabelValuesContainer = styled(Column)`
    gap: 10px;
    margin-top: 20px;
`;

const Header = styled(Row)`
    width: 100%;
    min-height: 30px;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 800 !important;
    background-color: #fff;
`;

const OptionsContainer = styled(Column)`
    width: 100%;
    max-height: 240px;
    gap: 10px;
    overflow-y: auto;
    padding: 0 30px 10px 30px;
`;

const Option = styled(Row)`
    width: 100%;
    height: 24px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
`;

const IndexContainer = styled(Column)`
    min-width: 20px;
`;
const SeparatorContainer = styled(Column)`
    width: 12px;
`;

const LabelContainer = styled.div``;
const ValueContainer = styled.div``;

const StyledTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 24px;
        margin: 0;
        border-radius: 0;
    }

    & .MuiInputBase-input {
        padding: 0 14px !important;
        color: ${({ theme }) => theme.palette.Text.onSurface.high};
    }

    & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.filled};
    }
`;

const ButtonContainer = styled.div`
    width: 40px;
    min-width: 40px;
    height: 24px;
`;

const AddButtonContainer = styled.div`
    width: 40px;
    height: 40px;
`;

export interface Props {
    control: Control;
    name: string;
    rules: RegisterOptions;
    handleChange?: (value: object) => void;
}

const PickListValuesEditor = ({ name, control, rules, handleChange }: Props) => {
    const renderHeader = () => {
        return (
            <Header>
                <IndexContainer />
                <LabelContainer>Badger Label</LabelContainer>
                <SeparatorContainer></SeparatorContainer>
                <ValueContainer>CRM API Name</ValueContainer>
                <ButtonContainer />
            </Header>
        );
    };

    const updateLabel = (index: number, value: string, field: ControllerRenderProps<FieldValues, string>) => {
        const newValue = [...field.value];
        newValue[index].label = value;
        if (handleChange) handleChange(newValue);
        field.onChange(newValue);
    };

    const updateValue = (index: number, value: string, field: ControllerRenderProps<FieldValues, string>) => {
        const newValue = [...field.value];
        newValue[index].value = value;
        if (handleChange) handleChange(newValue);
        field.onChange(newValue);
    };

    const deleteLabelAndValue = (index: number, field: ControllerRenderProps<FieldValues, string>) => {
        const newFieldValue = field.value.filter((_: PickListOption, i: number) => i !== index);
        field.onChange(newFieldValue);
        if (handleChange) handleChange(newFieldValue);
    };

    const renderOption = (index: number, option: PickListOption, field: ControllerRenderProps<FieldValues, string>) => {
        return (
            <Option>
                <IndexContainer>{index + 1}.</IndexContainer>
                <LabelContainer>
                    <StyledTextField
                        value={option.label}
                        onChange={(event) => updateLabel(index, event.target.value, field)}
                        error={!Boolean(option.label)}
                    />
                </LabelContainer>
                <SeparatorContainer>-</SeparatorContainer>
                <ValueContainer>
                    <StyledTextField
                        value={option.value}
                        onChange={(event) => updateValue(index, event.target.value, field)}
                        error={!Boolean(option.value)}
                    />
                </ValueContainer>
                <ButtonContainer>
                    <Button
                        type={ButtonTypes.TEXT}
                        severity={BUTTON_SEVERITY_LEVELS.ALERT}
                        icon={<Icon icon={IconNames.CLEAR} />}
                        onClick={() => deleteLabelAndValue(index, field)}
                    >
                        {''}
                    </Button>
                </ButtonContainer>
            </Option>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => {
                return field.value !== undefined ? (
                    <Container>
                        <Title>Edit picklist values</Title>
                        <LabelValuesContainer>
                            <OptionsContainer>
                                {renderHeader()}
                                {field.value
                                    .filter((option: PickListOption) => option.id !== '-1')
                                    .map((option: PickListOption, index: number) => renderOption(index, option, field))}
                                <AddButtonContainer>
                                    <Button
                                        type={ButtonTypes.TEXT}
                                        icon={<Icon icon={IconNames.ADD_FILLED} />}
                                        onClick={() => {
                                            const maxOrder = field.value.length
                                                ? field.value.reduce(
                                                      (
                                                          max: number,
                                                          option: {
                                                              label: string;
                                                              value: string;
                                                              order: number;
                                                          }
                                                      ) => (option.order > max ? option.order : max),
                                                      field.value[0].order
                                                  )
                                                : -1;

                                            field.onChange([
                                                ...field.value,
                                                {
                                                    label: '',
                                                    value: '',
                                                    order: maxOrder + 1,
                                                },
                                            ]);
                                        }}
                                    >
                                        {''}
                                    </Button>
                                </AddButtonContainer>
                            </OptionsContainer>
                        </LabelValuesContainer>
                    </Container>
                ) : (
                    <></>
                );
            }}
        />
    );
};

export default PickListValuesEditor;
