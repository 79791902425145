import { Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon, IconNames } from 'design-system';

const StyledListItem = styled(ListItem)<any>`
    width: ${({ open }) => (open ? '170px' : 'auto')} !important;
    height: 40px !important;
    display: block;
`;

const getRadius = (theme: any, isSelected: boolean) => {
    const radius = isSelected
        ? theme.palette.Measures.radius.mainMenu.button.selectedLeft
        : theme.palette.Measures.radius.mainMenu.button.enabled;
    return `
        border-radius: ${radius} !important;
        &:hover {
            border-radius: ${theme.palette.Measures.radius.mainMenu.button.hover} !important;
        }
    `;
};

const StyledListItemButton = styled(ListItemButton).withConfig({
    shouldForwardProp: (prop: any) => prop !== 'isSelected' && prop !== 'open',
})<any>`
    width: ${({ open }) => (open ? '170px' : '44px')} !important;
    height: 100% !important;
    ${({ theme, isSelected }) => getRadius(theme, isSelected)}

    background-color: ${({ theme, isSelected }) =>
        isSelected
            ? theme.palette.Background.button.mainMenu.selected
            : theme.palette.Background.button.mainMenu.enabled} !important;

    color: ${({ theme, isSelected }) =>
        isSelected
            ? theme.palette.Text.onButton.primary.enabled
            : theme.palette.Text.onButton.primary.selected} !important;

    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: ${({ open }) => (open ? '16px' : '10px')} !important;
    padding-right: ${({ open }) => (open ? '16px' : '10px')} !important;

    &:hover {
        background-color: ${({ theme }) => theme.palette.Background.button.primary.hover} !important;
        color: ${({ theme }) => theme.palette.Text.onButton.primary.hover} !important;
    }
`;

const StyledListItemIcon = styled(ListItemIcon).withConfig({
    shouldForwardProp: (prop: any) => prop !== 'isOpen',
})<any>`
    min-width: auto !important;
    margin-right: ${({ isOpen }) => (isOpen ? '10px' : '0px')} !important;
    color: inherit !important;
`;

const StyledTooltip = styled(({ className, ...props }: any) => <Tooltip {...props} classes={{ popper: className }} />)`
    & .MuiTooltip-tooltip {
        width: 81px;
        height: 45px;
        border-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.palette.Background.tooltip.default} !important;
    }

    & .MuiTooltip-arrow {
        color: ${({ theme }) => theme.palette.Background.tooltip.default};
    }
`;

interface MenuItemProps {
    isMenuOpen: boolean;
    title: string;
    path: string;
    isSelected: boolean;
    onClick: () => void;
}

const MenuItem = ({ isMenuOpen, title, path, isSelected, onClick }: MenuItemProps) => {
    const CustomNavLink = forwardRef((props: { className: string; to: string }, ref: React.Ref<any>) => (
        <NavLink
            ref={ref}
            {...props}
            className={({ isActive }) => (isActive ? props.className + ' Mui-selected' : props.className)}
            end
        />
    ));
    return (
        <StyledListItem disablePadding onClick={onClick} open={isMenuOpen}>
            <StyledListItemButton component={CustomNavLink} to={path} isSelected={isSelected} open={isMenuOpen}>
                <StyledTooltip title={isMenuOpen ? null : title} placement="right" arrow>
                    <StyledListItemIcon isOpen={isMenuOpen}>
                        <Icon icon={IconNames.COMPANIES} width={24} height={24} alt=""></Icon>
                    </StyledListItemIcon>
                </StyledTooltip>
                {isMenuOpen && <ListItemText primary={title} />}
            </StyledListItemButton>
        </StyledListItem>
    );
};

export default MenuItem;
