import { useEffect } from 'react';
import { useSelectCompany } from './useSelectCompany';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchCompanyUsers, selectCompanyUsers } from '../../store/slices/companyUsersSlice';

const useCompanyUsersRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectCompanyUsers);

    useEffect(() => {
        if (selectedCompanyId) {
            dispatch(fetchCompanyUsers(selectedCompanyId));
        }
    }, [selectedCompanyId]);

    return { users };
};

export { useCompanyUsersRepository };
