import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedCompanyId } from '../../../store/slices/companiesSlice';
import { useNavigate } from 'react-router-dom';
import { useCompanyUsersRepository } from '../../../common/hooks/useCompanyUsersRepository';

const useCompanyUsersMainContentController = () => {
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const navigate = useNavigate();
    const companyId = useAppSelector(selectSelectedCompanyId);
    const { users } = useCompanyUsersRepository();

    const onConfirm = () => {
        setShowAddUsersModal(false);
        navigate(`/companies/${companyId}/users/add-users`);
    };

    return { showAddUsersModal, setShowAddUsersModal, onConfirm, users };
};

export { useCompanyUsersMainContentController };

export type useCompanyUsersMainContentControllerType = typeof useCompanyUsersMainContentController;
