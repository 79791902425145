import { useParams } from 'react-router-dom';
import { selectSelectedCompanyId, updateSelectedCompanyId } from '../../store/slices/companiesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';

const useUpdateSelectedCompanyIdToMatchURL = () => {
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(selectSelectedCompanyId);
    const { id: companyUrlId } = useParams<{ id: string | undefined }>();

    useEffect(() => {
        dispatch(updateSelectedCompanyId(companyUrlId));
    }, [companyUrlId, dispatch]);

    return { companyId };
};

export { useUpdateSelectedCompanyIdToMatchURL };
