import { ObjectType } from '../../domain/Crm/ObjectType';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyCRMObjectTypesAPI } from '../api/companyCrmObjectTypes/CompanyCRMObjectTypesAPI';
import { RootState } from '../store';
import { selectUserToken } from './userAuthSlice';
import { selectSelectedCompanyId } from './companiesSlice';

type CompanyCrmObjectTypesState = {
    objectTypes: ObjectType[];
    isLoading: boolean;
};

const initialState: CompanyCrmObjectTypesState = {
    objectTypes: [],
    isLoading: false,
};

export const companyObjectTypesRetrievalRequested = createAsyncThunk(
    'companyCrmObjectTypes/companyObjectTypesRetrievalRequested',
    async (_, { getState, rejectWithValue }) => {
        const token = selectUserToken(getState() as RootState);
        if (!token) {
            return rejectWithValue('You need to be authenticated to retrieve company object types');
        }
        const companyId = selectSelectedCompanyId(getState() as RootState);
        if (!companyId) {
            return rejectWithValue('You need to select a company to retrieve company object types');
        }
        return await CompanyCRMObjectTypesAPI().getAll(companyId, token);
    }
);

const companyCrmObjectTypesSlice = createSlice({
    name: 'companyCrmObjectTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(companyObjectTypesRetrievalRequested.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(companyObjectTypesRetrievalRequested.fulfilled, (state, action) => {
            state.objectTypes = action.payload;
            state.isLoading = false;
        });
        builder.addCase(companyObjectTypesRetrievalRequested.rejected, (state) => {
            state.objectTypes = [];
            state.isLoading = false;
        });
    },
});

export const selectCompanyCrmObjectTypes = (state: { companyCrmObjectTypes: CompanyCrmObjectTypesState }) => {
    return state.companyCrmObjectTypes.objectTypes;
};

export const selectIsLoadingCompanyCrmObjectTypes = (state: { companyCrmObjectTypes: CompanyCrmObjectTypesState }) => {
    return state.companyCrmObjectTypes.isLoading;
};

export default companyCrmObjectTypesSlice.reducer;
