import { CsaUser } from '../Users/CsaUser';

export enum State {
    ACTIVE,
    INACTIVE,
}

type Company = {
    id?: string;
    name: string;
    csa?: CsaUser;
    isHipaa: boolean;
    numberOfActiveUsers?: number;
    state?: State;
    crm?: string;
};

export default Company;
