import { useEffect, useState } from 'react';
import { AvailableStaffRepository } from '../repositories/AvailableStaffRepository';
import { User } from '../../domain/Users/User';
import { CsaUser } from '../../domain/Users/CsaUser';
import { CompaniesRepository } from '../repositories/CompaniesRepository';
import Company from '../../domain/Companies/Company';

const useCreateCompanyModalController = () => {
    const [isHipaa, setIsHipaa] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [staff, setStaff] = useState<CsaUser>();
    const [availableStaff, setAvailableStaff] = useState<User[]>([]);

    const { getAvailableStaff } = AvailableStaffRepository();

    const { createCompany: createCompanyInRepository } = CompaniesRepository();

    const createCompany = (name: string, csa: CsaUser | undefined, isHipaa: boolean) => {
        const company: Company = {
            name: name,
            isHipaa: isHipaa,
        };
        if (csa !== undefined) {
            company.csa = csa;
        }
        createCompanyInRepository(company);
    };

    useEffect(() => {
        const getStaff = async () => {
            try {
                setAvailableStaff(await getAvailableStaff());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getStaff();
    }, []);

    return {
        companyName,
        setCompanyName,
        setIsHipaa: setIsHipaa,
        isHipaa: isHipaa,
        staff,
        setStaff,
        availableStaff,
        setAvailableStaff,
        createCompany,
    };
};

export { useCreateCompanyModalController };

export type useCreateCompanyModalControllerType = typeof useCreateCompanyModalController;
