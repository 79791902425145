import { useEffect, useMemo, useState } from 'react';
import { Row, ROW_COLUMN_SEPARATOR, SortingOrderType } from '../../defs';
import { BadgerObjectDefinition, DisplayType } from '../../../../domain/CompanyDefinition/CompanyDefinition';
import { useForm } from 'react-hook-form';

const useFormController = () => {
    const [defaultValues, setDefaultValues] = useState<any>({});
    const [fields, setFields] = useState<any[]>([]);
    const { handleSubmit, control, getValues, setValue, watch, formState, reset } = useForm({
        defaultValues: useMemo(() => defaultValues, [defaultValues]),
        mode: 'onChange',
    });
    const [keepDirty, setKeepDirty] = useState<boolean>(false);
    const { isDirty, isValid } = formState;
    const [mainAddressField, setMainAddressField] = useState<string>('');

    useEffect(() => {
        if (Object.keys(defaultValues)?.length) {
            reset(defaultValues, { keepDirty });
            setKeepDirty(false);
        }
    }, [defaultValues, reset]);

    const values = watch();
    useEffect(() => {
        const names = Object.keys(values).filter((item: any) => item.includes('//name'));
        const updatedFields = names.map((name: any) => {
            const uuid = name.split(ROW_COLUMN_SEPARATOR)[0];
            const label = values[`${uuid}${ROW_COLUMN_SEPARATOR}label`];
            const type = values[`${uuid}${ROW_COLUMN_SEPARATOR}type`];
            return { label, name: values[name], type, uuid };
        });
        if (JSON.stringify(updatedFields) !== JSON.stringify(fields)) {
            setFields(updatedFields);
        }
        setMainAddressField(values.mainAddress);
    }, [values]);

    const buildTitle = (rows: Row[] | undefined) =>
        rows ? rows.filter((row: Row) => row.displayType === DisplayType.AS_TITLE).map((row: Row) => row.uuid) : [];

    const buildSubTitle = (rows: Row[] | undefined) =>
        rows ? rows.filter((row: Row) => row.displayType === DisplayType.AS_SUBTITLE).map((row: Row) => row.uuid) : [];

    const buildSorting = (rows: Row[] | undefined) => {
        const row = rows?.find((row: Row) => row.sortingOrder !== SortingOrderType.UNDEFINED);
        return row
            ? {
                  uuid: row.uuid,
                  name: row.name,
                  direction: row.sortingOrder,
              }
            : null;
    };

    const buildSearchCriteria = (rows: Row[] | undefined) =>
        rows ? rows.filter((row: Row) => row.isSearchable).map((row: Row) => row.uuid) : [];

    const buildMainAddress = (rows: Row[] | undefined) => rows?.find((row: Row) => row.isMain)?.uuid || '';

    const buildDefaultValues = (
        columns: any[],
        rows: Row[] | undefined,
        objectDefinition: BadgerObjectDefinition | undefined
    ) => {
        const defaultValues: any = {};
        rows?.forEach((row: any) => {
            columns.forEach((column: any) => {
                const key = `${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`;
                defaultValues[key] = row[column.name];
            });
        });
        defaultValues.title = buildTitle(rows);
        defaultValues.subtitle = buildSubTitle(rows);
        const sortingOrder = buildSorting(rows);
        defaultValues.sortingOrder = sortingOrder?.uuid || '';
        defaultValues.orderDirection = sortingOrder?.direction || '';
        defaultValues.searchCriteria = buildSearchCriteria(rows);
        defaultValues.mainAddress = buildMainAddress(rows);
        defaultValues.isRoot = objectDefinition?.isRoot || false;

        setDefaultValues(defaultValues);
        return defaultValues;
    };

    const addRowValues = (baseName: string, displayOrder: number) => {
        setValue(`${baseName}decimals`, null, { shouldDirty: true });
        setValue(`${baseName}displayOrder`, displayOrder, { shouldDirty: true });
        setValue(`${baseName}isColorizable`, null, { shouldDirty: true });
        setValue(`${baseName}isFilterable`, null, { shouldDirty: true });
        setValue(`${baseName}isMandatory`, false, { shouldDirty: true });
        setValue(`${baseName}isTextArea`, false, { shouldDirty: true });
        setValue(`${baseName}isSearchable`, false, { shouldDirty: true });
        setValue(`${baseName}isViewOnly`, false, { shouldDirty: true });
        setValue(`${baseName}label`, '', { shouldDirty: true });
        setValue(`${baseName}name`, '', { shouldDirty: true });
        setValue(`${baseName}pickList`, null, { shouldDirty: true });
        setValue(`${baseName}type`, null, { shouldDirty: true });
    };

    const updateRowValues = (baseName: string, values: any) => {
        setValue(`${baseName}isMandatory`, values.isMandatory, { shouldDirty: true });
        setValue(`${baseName}isSearchable`, values.isSearchable, { shouldDirty: true });
        setValue(`${baseName}isColorizable`, values.isColorizable, { shouldDirty: true });
        setValue(`${baseName}isFilterable`, values.isFilterable, { shouldDirty: true });
        setValue(`${baseName}isTextArea`, values.isFilterable, { shouldDirty: true });
        setValue(`${baseName}isViewOnly`, values.isViewOnly, { shouldDirty: true });
        setValue(`${baseName}pickList`, values.pickList, { shouldDirty: true });
        setValue(`${baseName}decimals`, values.decimals, { shouldDirty: true });
    };

    const deleteRowValues = (baseName: string, uuid: string) => {
        setValue(`${baseName}label`, '--deleted-field', { shouldDirty: true });
        const values: any = {};
        const currentValues = getValues();

        currentValues.title = currentValues.title.filter((fieldUuid: string) => fieldUuid !== uuid);
        currentValues.subtitle = currentValues.subtitle.filter((fieldUuid: string) => fieldUuid !== uuid);
        currentValues.sortingOrder = currentValues.sortingOrder === uuid ? null : currentValues.sortingOrder;
        currentValues.searchCriteria = currentValues.searchCriteria.filter((fieldUuid: string) => fieldUuid !== uuid);
        currentValues.mainAddress = currentValues.mainAddress === uuid ? '' : currentValues.mainAddress;

        Object.keys(currentValues).forEach((key: string) => {
            if (!key.includes(baseName)) {
                values[key] = currentValues[key];
            }
        });

        setDefaultValues(values);
        setKeepDirty(true);
    };

    const changeOrder = (baseName: string, order: number) => {
        const name = `${baseName}displayOrder`;
        setValue(name, order, { shouldDirty: true });
    };

    const setViewOnlyExclusion = (baseName: string, field: string) => {
        setValue(`${baseName}${field}`, false, { shouldDirty: true });
    };

    return {
        getValues,
        buildDefaultValues,
        isDirty,
        isValid,
        handleSubmit,
        control,
        watch,
        addRowValues,
        updateRowValues,
        deleteRowValues,
        changeOrder,
        setViewOnlyExclusion,
        fields,
        mainAddressField,
    };
};

export default useFormController;
