import { useCompanyCrmUsersMapper } from './useCompanyCrmUsersMapper';
import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import { useCompanyCrmUserCreationValidationMapper } from './useCompanyCrmUserCreationValidationMapper';
import { DraftUser } from '../../../domain/Users/DraftUser';

const CompanyCrmUsersAPI = () => {
    const { fromAPI: companyCrmUsersMapper, toAPI } = useCompanyCrmUsersMapper();
    const { fromAPI: companyCrmUserCreationValidationMapper } = useCompanyCrmUserCreationValidationMapper();
    const { fetch } = HttpClient();

    const getAllForCompany = async (companyId: string | undefined, token: string | undefined) => {
        const url = `/api/3/crm/companies/${companyId}/external-users`;
        return await fetch(HttpMethod.GET, url, token)
            .then((response: any) => {
                return response.status != '200' ? [] : companyCrmUsersMapper(response.body);
            })
            .catch((error) => {
                throw error;
            });
    };

    const createUsers = async (companyId: string | undefined, token: string | undefined, draftUsers: DraftUser[]) => {
        const url = `/api/3/crm/companies/${companyId}/users`;
        const transformedDraftUsers = toAPI(draftUsers);
        const { status, body } = await fetch(HttpMethod.POST, url, token, { users: transformedDraftUsers });

        if (status !== 200) {
            return [];
        }

        return companyCrmUserCreationValidationMapper(body);
    };

    return { getAllForCompany, createUsers };
};

export { CompanyCrmUsersAPI };
