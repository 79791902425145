import styled from 'styled-components';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { State } from '../domain/Companies/Company';
import { ActiveCompanyIcon, InactiveCompanyIcon } from '../common/components/ActiveStatusComponent';
import { useCompaniesMainContentControllerType } from './hooks/useCompaniesMainContentController';
import { StyledDataGrid } from '../common/components/StyledDataGrid';
import { Button } from 'design-system';
import { CreateCompanyModal } from './CreateCompanyModal';
import {
    useCreateCompanyModalController,
    useCreateCompanyModalControllerType,
} from './hooks/useCreateCompanyModalController';

const columns: GridColDef[] = [
    {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'id',
        flex: 1,
        headerName: 'Company ID',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'csa',
        flex: 1,
        headerName: 'CSA',
        headerAlign: 'left',
        align: 'left',
        valueGetter: (params) => (params.value ? params.value.name : '-'),
    },
    {
        field: 'numberOfActiveUsers',
        flex: 1,
        headerName: 'Active users',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'state',
        flex: 1,
        headerName: 'State',
        headerAlign: 'left',
        align: 'left',
        renderCell: (params: GridCellParams) => {
            return params.value === State.ACTIVE ? <ActiveCompanyIcon /> : <InactiveCompanyIcon />;
        },
    },
];
const StyledBoxDataGridContainer = styled(Box)`
    width: 100%;
    padding: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const ControlsContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;
    height: 60px;
`;

const CompaniesMainContent = (props: {
    'data-testid'?: string;
    useCompaniesMainContentController: useCompaniesMainContentControllerType;
    useCreateCompanyModalController: useCreateCompanyModalControllerType;
}) => {
    const { companies, handleOnCompanyClick, showAddCompanyModal, setShowAddCompanyModal } =
        props.useCompaniesMainContentController();

    return (
        <StyledBoxDataGridContainer {...props}>
            <ControlsContainer>
                <Box>
                    <Button onClick={() => setShowAddCompanyModal(true)}>Add company</Button>
                </Box>
            </ControlsContainer>
            {showAddCompanyModal && (
                <CreateCompanyModal
                    isOpen={showAddCompanyModal}
                    useController={useCreateCompanyModalController}
                    onClose={() => {
                        setShowAddCompanyModal(false);
                    }}
                />
            )}
            <StyledDataGrid
                rows={companies}
                columns={columns}
                columnBuffer={6}
                disableRowSelectionOnClick
                onRowClick={(params) => handleOnCompanyClick(params.row.id)}
            />
        </StyledBoxDataGridContainer>
    );
};

export default CompaniesMainContent;
