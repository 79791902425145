import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useNavigate } from 'react-router-dom';
import { useSelectedCompanyName } from '../../common/hooks/useSelectedCompanyName';

export const useCompanyDefinitionHeaderController = () => {
    const { selectedCompanyId } = useSelectCompany();
    const { selectedCompanyName } = useSelectedCompanyName();
    const navigate = useNavigate();

    const goBackToUsers = () => navigate(`/companies/${selectedCompanyId}/users`);
    const goToCompanies = () => navigate('/companies');

    return { selectedCompanyName, goToCompanies, goBackToUsers };
};

export type CompanyDefinitionHeaderControllerType = typeof useCompanyDefinitionHeaderController;
