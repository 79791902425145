type Configuration = {
    backendHost: string | undefined;
    isCompanyDefinitionManagementEnabled: boolean;
};

const getConfiguration = (): Configuration => {
    const backendHost = process.env.REACT_APP_BACKEND_HOST || '';
    const isCompanyDefinitionManagementEnabled = process.env.REACT_APP_COMPANY_DEFINITION_MANAGEMENT_ENABLED === 'true';
    return { backendHost, isCompanyDefinitionManagementEnabled };
};
const configuration = getConfiguration();
const { backendHost, isCompanyDefinitionManagementEnabled } = configuration;

export { configuration, backendHost, isCompanyDefinitionManagementEnabled, getConfiguration };
