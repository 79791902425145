import styled from 'styled-components';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Box, Grid, Stack } from '@mui/material';
import { Icon, IconNames, loadFontAttributes } from 'design-system';
import { useCompanyHeaderControllerType } from './CompanyUsersPage/hooks/useCompanyHeaderController';
import { isCompanyDefinitionManagementEnabled } from '../common/configuration/ConfigProvider';

const StyledAppBar = styled(MuiAppBar)`
    background-color: ${({ theme }) => theme.palette.Background.bars.Color} !important;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    position: relative !important;
    height: 100%;
`;

const StyledGrid = styled(Grid)`
    padding: 10px 30px;
    height: 100%;
`;

const StyledCompanyName = styled(Typography)`
    text-align: center;
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const StyledPath = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    cursor: pointer;
`;

const StyledPathCompanyName = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const StyledData = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;
const StyledId = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const ToolsContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
`;

const StyledIcon = styled(Icon)`
    cursor: pointer;
`;

const CompanyHeader = ({ useController }: { useController: useCompanyHeaderControllerType }) => {
    const { selectedCompanyName, selectedCompanyId, goBackToCompanies, goToFieldSettings } = useController();

    return (
        <StyledAppBar elevation={0} data-testid={'t-company-header'}>
            <StyledGrid container>
                <Grid item xs={4}>
                    <Stack direction={'row'} spacing={1}>
                        <StyledPath onClick={goBackToCompanies}>Companies /</StyledPath>
                        <StyledPathCompanyName>{selectedCompanyName}</StyledPathCompanyName>
                    </Stack>
                </Grid>
                <Grid item container xs={4} alignItems={'center'} justifyContent={'center'}>
                    <Stack spacing={1}>
                        <StyledCompanyName>{selectedCompanyName}</StyledCompanyName>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                            <StyledData>Company ID: </StyledData>
                            <StyledId>{selectedCompanyId}</StyledId>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <ToolsContainer>
                        {isCompanyDefinitionManagementEnabled && (
                            <StyledIcon
                                icon={IconNames.WHEEL}
                                width={24}
                                onClick={goToFieldSettings}
                                aria-label="wheel-icon"
                            />
                        )}
                    </ToolsContainer>
                </Grid>
            </StyledGrid>
        </StyledAppBar>
    );
};

export default CompanyHeader;
