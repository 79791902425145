import Typography from '@mui/material/Typography';
import AccessForbiddenIcon from './access_forbidden_icon.png';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { loadFontAttributes } from 'design-system';

const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const StyledImage = styled.img`
    max-width: 142px;
    max-height: 101px;
`;

const StyledTitle = styled(Typography)`
    /* Otter/XX-Large/medium/left */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.medium)}
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};

    margin: 38px 0 !important;
`;

const StyledHint = styled(Typography)`
    /* Otter/XX-Large/medium/left */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)}
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
`;

const AccessForbiddenComponent = () => {
    return (
        <StyledContainer>
            <StyledImage src={AccessForbiddenIcon} alt={'Access forbidden icon'} />
            <StyledTitle>403: Access Denied/Forbidden</StyledTitle>
            <StyledHint>We’re sorry, but you do not have permission to access to this page.</StyledHint>
        </StyledContainer>
    );
};

export default AccessForbiddenComponent;
