import { useCompanyUsersMapper } from './useCompanyUsersMapper';
import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';

const CompanyUsersAPI = () => {
    const { fromAPI } = useCompanyUsersMapper();
    const { fetch } = HttpClient();

    const getAllForCompany = async (companyId: string | undefined, token: string | undefined) => {
        const url = `/api/3/companies/${companyId}/users`;
        return await fetch(HttpMethod.GET, url, token)
            .then((response: any) => {
                return fromAPI(response.body);
            })
            .catch((error) => {
                throw error;
            });
    };

    return { getAllForCompany };
};

export { CompanyUsersAPI };
