import { useSelectSelectedUsers } from '../../common/hooks/useSelectSelectedUsers';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const useAddUsersToBadgerHeaderController = () => {
    const [showUsersNotSavedWarning, setShowUsersNotSavedWarning] = useState(false);
    const { selectSelectedCrmUserIds } = useSelectSelectedUsers();
    const navigate = useNavigate();

    const handleOnCancel = () => {
        selectSelectedCrmUserIds([]);
        navigate(-1);
    };

    return { handleOnCancel, showUsersNotSavedWarning, setShowUsersNotSavedWarning };
};

export { useAddUsersToBadgerHeaderController };
