import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchCompanies, selectCompanies, selectSelectedCompanyName } from '../../store/slices/companiesSlice';

const COMPANY_NOT_SELECTED_PROMPT = 'No company selected';

const useSelectedCompanyName = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(selectCompanies);
    const selectedCompanyName = useAppSelector(selectSelectedCompanyName);

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [companies]);

    return { selectedCompanyName: selectedCompanyName || COMPANY_NOT_SELECTED_PROMPT };
};

export { useSelectedCompanyName, COMPANY_NOT_SELECTED_PROMPT };
