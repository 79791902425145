import { HttpClient, HttpMethod } from '../api/HttpClient';
import { useAppSelector } from '../../store/hooks';
import { selectUserAuth } from '../../store/slices/userAuthSlice';

const useGetToken = () => {
    const { fetch } = HttpClient();
    const { token } = useAppSelector(selectUserAuth);

    const getTokenFromAPI = async (
        username: string,
        password: string
    ): Promise<{
        token?: string;
        userId?: string;
        error?: string;
    }> => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        try {
            const { status, body } = await fetch(HttpMethod.POST, '/api/2/login/', token, formData, {
                enctype: 'multipart/form-data',
            });

            if (status !== 200) {
                return { error: 'Unknown login error. Please contact support.' };
            }

            if (body.status === 'error_authentication_failed') {
                return { error: 'Invalid username or password' };
            }

            return { token: `Token ${body.token}`, userId: body.user_id };
        } catch (error: any) {
            console.error(error);
            return { error: 'Unknown login error. Please contact support.' };
        }
    };

    return { getTokenFromAPI };
};

export { useGetToken };
