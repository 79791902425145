import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useEffect } from 'react';
import { useCompanyDefinitionRepository } from '../../common/hooks/useCompanyDefinitionRepository';

export const useCompanyDefinitionContentController = () => {
    const location = useLocation();
    const pathLastElement = location.pathname.split('/').slice(-1)[0];
    const navigate = useNavigate();
    const { selectedCompanyId } = useSelectCompany();
    const { companyDefinition } = useCompanyDefinitionRepository();

    const tabs = companyDefinition
        ? companyDefinition.badgerObjectDefinitions.map((badgerObjectDefinition: any) => ({
              label: badgerObjectDefinition.labelPlural,
              pathIdentifier: badgerObjectDefinition.namePlural,
          }))
        : [];

    useEffect(() => {
        if (tabs.length) {
            const index = tabs.findIndex((segment: any) => segment.pathIdentifier.includes(pathLastElement));
            if (index === -1) {
                navigate(`/companies/${selectedCompanyId}/field-settings/${tabs[0].pathIdentifier}`);
            }
        }
    });

    return { tabs };
};

export type CompanyDefinitionContentControllerType = typeof useCompanyDefinitionContentController;
