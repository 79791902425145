import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserAuth from '../hooks/useUserAuth';

const ProtectedRoute = ({ children }: { children: ReactElement | ReactElement[] }) => {
    const { userAuth } = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userAuth.token) {
            navigate('/login');
        }
    }, [userAuth.token, navigate]);

    return <>{children}</>;
};

export default ProtectedRoute;
