import { useEffect } from 'react';
import { useSelectCompany } from './useSelectCompany';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCompanyDefinition } from '../../store/slices/companySlice/companySlice';
import { fetchCompanyDefinition } from '../../store/slices/companySlice/thunks';

const useCompanyDefinitionRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const dispatch = useAppDispatch();
    const companyDefinition = useAppSelector(selectCompanyDefinition);

    useEffect(() => {
        if (selectedCompanyId) {
            dispatch(fetchCompanyDefinition(selectedCompanyId));
        }
    }, [selectedCompanyId]);

    return { companyDefinition };
};

export { useCompanyDefinitionRepository };
