import { SegmentedControls } from 'design-system';
import { useCompanyControlPanelControllerType } from './hooks/useCompanyControlPanelController';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 30px;
`;

export interface Props {
    useController: useCompanyControlPanelControllerType;
}

const CompanyControlPanel = ({ useController }: Props) => {
    const { selectedSegment, onSegmentSelection, segmentsDataWithPathIdentifier } = useController();

    return (
        <Container>
            <SegmentedControls
                selectedSegment={selectedSegment}
                onChange={onSegmentSelection}
                segmentsData={segmentsDataWithPathIdentifier.map((segment) => ({
                    label: segment.label,
                    value: segment.pathIdentifier,
                }))}
                variant="standard"
                size="large"
            />
        </Container>
    );
};

export default CompanyControlPanel;
