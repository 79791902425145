import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { ImportError } from '../../../domain/Records/ImportError';

type Issue = {
    field_name: string;
    error_name: string;
    severity: string;
    description: string;
    recommendations: string[];
};

type Result = {
    external_record_id: string;
    object_type: string;
    issues: Issue[];
};

type JsonResponse = {
    results: Result[];
};

export const ImportErrorsApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    const transformResponse = (response: JsonResponse) => {
        return response.results.flatMap(
            (result) =>
                result.issues.map((issue) => ({
                    externalRecordId: result.external_record_id,
                    objectType: result.object_type,
                    fieldName: issue.field_name,
                    errorName: issue.error_name,
                    severity: issue.severity,
                    description: issue.description,
                    recommendations: issue.recommendations,
                })) as ImportError[]
        );
    };

    return {
        getImportErrors: async (importId: string) => {
            try {
                const response = await fetch(HttpMethod.GET, `/api/3/imports/${importId}/errors`, userAuth.token);
                return transformResponse(response.body as JsonResponse);
            } catch {
                return null;
            }
        },
    };
};
