import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UserAuthState {
    userId?: string;
    token?: string;
    error?: string;
}

const initialState: UserAuthState = {
    userId: undefined,
    token: undefined,
    error: undefined,
};

const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        updateUserAuth: (state, action: PayloadAction<UserAuthState>) => {
            state.userId = action.payload.userId;
            state.token = action.payload.token;
            state.error = action.payload.error;
        },
        logOut: (state) => {
            state.userId = undefined;
            state.token = undefined;
            state.error = undefined;
        },
    },
});

export const { updateUserAuth, logOut } = userAuthSlice.actions;

export const selectUserAuth = (state: RootState) => {
    return state.userAuth;
};

export const selectUserToken = (state: RootState) => {
    return state.userAuth.token;
};

export default userAuthSlice.reducer;
