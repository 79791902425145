import { useState } from 'react';
import { useCompanyCrmUsersRepository } from './useCompanyCrmUsersRepository';
import { useSelectSelectedUsers } from '../../../common/hooks/useSelectSelectedUsers';
import { useSelectDraftUsers } from '../../../common/hooks/useSelectDraftUsers';
import { CrmUser } from '../../../domain/Users/CrmUser';

const useMultiUserSelectorController = () => {
    const { users, loading } = useCompanyCrmUsersRepository();
    const { selectedCrmUserIds, selectSelectedCrmUserIds } = useSelectSelectedUsers();
    const { draftUsers, selectDraftUsersToUpdate } = useSelectDraftUsers();
    const [selectedCrmUsersIdsInSelector, setSelectedCrmUsersIdsInSelector] = useState<string[]>(selectedCrmUserIds);

    const handleOnUserClick = (userId: string) => {
        setSelectedCrmUsersIdsInSelector((prevSelected) => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter((user) => user !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleOnCancel = (userIdToDelete: string) => {
        selectSelectedCrmUserIds(selectedCrmUserIds.filter((userId) => userId !== userIdToDelete));
        selectDraftUsersToUpdate(draftUsers.filter((user) => user.crmUserId !== userIdToDelete));
        handleOnUserClick(userIdToDelete);
    };

    const buildDraftUser = (user: CrmUser) => {
        const username = user.email.split('@')[0];
        return `go.${username}`;
    };

    const handleOnConfirm = () => {
        const existingCrmUserIds = draftUsers.map((draftUser) => draftUser.crmUserId);
        const newUsersToDisplay = users.filter((user) => {
            return (
                selectedCrmUsersIdsInSelector.includes(user.crmUserId) && !existingCrmUserIds.includes(user.crmUserId)
            );
        });
        const newDraftUsers = newUsersToDisplay.map((user) => ({
            crmUserId: user.crmUserId,
            firstName: user.firstName,
            lastName: user.lastName,
            username: buildDraftUser(user),
            email: user.email,
            password: 'badger01',
        }));
        selectSelectedCrmUserIds(selectedCrmUsersIdsInSelector);
        selectDraftUsersToUpdate([...draftUsers, ...newDraftUsers]);
    };

    return { users, selectedCrmUsersIdsInSelector, loading, handleOnUserClick, handleOnCancel, handleOnConfirm };
};

export { useMultiUserSelectorController };

export type useMultiUserSelectorControllerType = typeof useMultiUserSelectorController;
