import Company from '../../domain/Companies/Company';
import { CompanyCreationRequestDTO } from './CompaniesRepository';

export const CompanyToRequestDTOMapper = () => {
    const getCompanyCsaId = (company: Company): string => {
        if (!company.csa || !company.csa.id) {
            throw Error('Company creation requires the csa with id property');
        }
        return company.csa.id;
    };
    const map = (company: Company): CompanyCreationRequestDTO => {
        const dto: CompanyCreationRequestDTO = {
            name: company.name,
            isHipaa: company.isHipaa,
        };
        if (company.csa) {
            dto.csa = {
                id: getCompanyCsaId(company),
            };
        }
        return dto;
    };

    return { map };
};
