import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { RecordDefinition } from '../../../domain/Records/RecordDefinition';
import { BulkStatus, DataSync, DataSyncStatus } from '../../../domain/Crm/DataSync';

type Body = {
    id: string;
    company_id: string;
    import_id: string;
    requester_user_id: string;
    status: string;
    bulk_states: {
        object_type: string;
        bulk_id: string;
        progress: {
            status: string;
            error_msg: string;
        };
    }[];
    error_msg: string;
};

export const DataSyncTriggerApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    const _createBody = (objectTypes: RecordDefinition[]) => {
        return {
            objects: objectTypes.map((objectType) => ({
                object_type: objectType.object_type,
            })),
        };
    };

    const _tryAgainBody = (objectTypes: DataSync) => {
        return {
            objects: objectTypes.bulkStates
                .filter((item) => item.progress.status === 'ERROR')
                .map((item) => ({
                    object_type: item.objectType,
                    existing_bulk_id: item.bulkId,
                })),
        };
    };

    const transformResponse = (body: Body): DataSync => {
        return {
            id: body.id,
            companyId: body.company_id,
            importId: body.import_id,
            requesterUserId: body.requester_user_id,
            status: body.status as DataSyncStatus,
            bulkStates: body.bulk_states.map((state) => ({
                objectType: state.object_type,
                bulkId: state.bulk_id,
                progress: {
                    status: state.progress.status as BulkStatus,
                    errorMsg: state.progress.error_msg,
                },
            })),
            errorMsg: body.error_msg,
        };
    };

    const triggerDataSync = async (companyId: string, recordDefinitionsToSync: RecordDefinition[]) => {
        try {
            const response = await fetch(
                HttpMethod.POST,
                `/api/3/crm/companies/${companyId}/data-syncs`,
                userAuth.token,
                _createBody(recordDefinitionsToSync)
            );
            return transformResponse(response.body);
        } catch (error) {
            console.log('Error while triggering data sync');
            return null;
        }
    };

    const tryAgainDataSync = async (companyId: string, dataSync: DataSync) => {
        try {
            const response = await fetch(
                HttpMethod.POST,
                `/api/3/crm/companies/${companyId}/data-syncs`,
                userAuth.token,
                _tryAgainBody(dataSync)
            );
            return transformResponse(response.body);
        } catch (error) {
            console.log('Error while triggering data sync');
            return null;
        }
    };

    return {
        triggerDataSync,
        tryAgainDataSync,
    };
};
