import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    ActionType,
    clearMessage as clearStoreMessage,
    Message,
    MessageSeverity,
    selectMessages,
} from '../../../store/slices/feedbackSlice';
import { useNavigate } from 'react-router-dom';
import { SnackBarStates } from 'design-system';

export const useFeedbackController = () => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector(selectMessages);
    const navigate = useNavigate();

    const firstMessage: Message | null = messages.length ? messages[0] : null;

    const getSnackbarTypeFromSeverity = () => {
        switch (firstMessage?.severity) {
            case MessageSeverity.ERROR:
                return SnackBarStates.ALERT;
            case MessageSeverity.WARNING:
                return SnackBarStates.INFO;
            case MessageSeverity.INFO:
                return SnackBarStates.INFO;
            default:
                return SnackBarStates.SUCCESS;
        }
    };

    const buildActionHandler = () => {
        if (firstMessage?.actionType === ActionType.NAVIGATE) {
            return () => {
                navigate(firstMessage.actionParameters);
                clearMessage();
            };
        }
    };

    const clearMessage = () => {
        if (firstMessage) {
            dispatch(clearStoreMessage(firstMessage.id));
        }
    };

    return {
        firstMessage,
        clearMessage,
        buildActionHandler,
        getSnackbarTypeFromSeverity,
    };
};

export default { useFeedbackController };
