import { useCompaniesMapper } from './useCompaniesMapper';
import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';

const CompaniesAPI = () => {
    const { fromAPI } = useCompaniesMapper();

    const getAll = async (token: string | undefined) => {
        const { fetch } = HttpClient();
        const path = '/api/3/companies';
        const { body } = await fetch(HttpMethod.GET, path, token);
        return fromAPI(body);
    };

    return { getAll };
};

export { CompaniesAPI };
