import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { HTTP_401_ERROR, HTTP_403_ERROR } from '../common/api/HttpClient';

// Use throughout the app instead of plain `useDispatch` and `useSelector`
// These are typed versions of the original hooks
export const useTypedAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const HANDLED_ERROR_CODES = [HTTP_401_ERROR.toString()];
export const ERROR_BOUNDARY_HANDLED_ERROR_CODES = [HTTP_403_ERROR.toString()];

export const useAppDispatch = () => {
    const dispatch = useDispatch();
    return (action: any) => {
        const _ = dispatch(action);
        if ('unwrap' in _ && typeof _.unwrap === 'function') {
            _.unwrap().catch((error: any) => {
                if (
                    (!isHandledError(error) && !isServerError(error) && !isClientError(error)) ||
                    isErrorBoundaryHandledCode(error)
                ) {
                    throwUnhandledError(error); // Let the error boundary handle this
                }
            });
        }
        return _;
    };
};

const throwUnhandledError = (error: any) => {
    throw error;
};

const isErrorBoundaryHandledCode = (error: any) => {
    return ERROR_BOUNDARY_HANDLED_ERROR_CODES.includes(error.message);
};

const isHandledError = (error: any) => {
    return HANDLED_ERROR_CODES.includes(error.message);
};

const isServerError = (error: any) => {
    return Math.floor(Number(error.message) / 100) === 5;
};

const isClientError = (error: any) => {
    return Math.floor(Number(error.message) / 100) === 4;
};
