import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Fragment } from 'react';
import { Dialog, Button } from 'design-system';
import { Box } from '@mui/material';

type ModalSelectorObject = {
    id: string;
    primaryText: string;
    secondaryText: string;
};

export type { ModalSelectorObject };

export interface UniversalModalSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    objects: ModalSelectorObject[];
    selectedObjectIds: string[];
    loading: boolean;
    handleOnObjectClick: (id: string) => void;
    handleOnConfirm: () => void;
    modalTitle: string;
}

const StyledList = styled(List)`
    &.MuiList-root {
        padding: 0;
    }
`;

const StyledListItem = styled(ListItem)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
`;

const ModalSelector = ({
    visible,
    onConfirm,
    onClose,
    objects,
    selectedObjectIds,
    loading,
    handleOnObjectClick,
    handleOnConfirm,
    modalTitle,
}: UniversalModalSelectorProps) => {
    const content = (
        <StyledList>
            {objects.map((object) => (
                <Fragment key={object.id}>
                    <StyledListItem>
                        <ListItemText primary={object.primaryText} secondary={object.secondaryText} />
                        <Box>
                            <Button onClick={() => handleOnObjectClick(object.id)}>Select</Button>
                        </Box>
                    </StyledListItem>
                    <Divider variant="fullWidth" component="li" />
                </Fragment>
            ))}
        </StyledList>
    );

    return (
        <Dialog
            title={modalTitle}
            content={content}
            primaryButtonCaption={`Confirm ${selectedObjectIds.length} of objects`}
            onPrimaryButtonClick={() => {
                handleOnConfirm();
                onConfirm();
            }}
            onClose={onClose}
            open={visible}
            loading={loading}
            primaryButtonDisabled={selectedObjectIds.length === 0 || loading}
        />
    );
};

export default ModalSelector;
