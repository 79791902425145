import { backendHost } from '../configuration/ConfigProvider';

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

export const HTTP_401_ERROR = 401;
export const HTTP_403_ERROR = 403;
export const HTTP_500_ERROR = 500;

export const HttpClient = () => {
    const doFetch = async (
        method: HttpMethod,
        endpoint: string,
        token: string | undefined,
        body?: any,
        headers?: Record<string, string>
    ): Promise<{ status: number; body: any }> => {
        return new Promise((resolve, reject) => {
            const fullURL = `${backendHost}${endpoint}`;

            fetch(fullURL, {
                method: method,
                body: getRawBody(headers, body),
                headers: getHeaders(token, headers),
            })
                .then(async (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve({ status: response.status, body: await response.json() });
                    } else {
                        reject(new Error(response.status.toString(), { cause: response }));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    // What is this EncType header thing you may wonder? https://stackoverflow.com/a/72872993
    // When we rework the login endpoint and stop sending form data, we can remove this.
    const getRawBody = (headers?: Record<string, string>, body?: any) => {
        if (headers?.enctype) {
            return body;
        }
        return JSON.stringify(body);
    };

    const getHeaders = (token: string | undefined, headers?: Record<string, string>) => {
        const headersCopy = { ...headers };
        if (headers?.enctype) return headersCopy;
        return {
            'Content-Type': 'application/json',
            Authorization: token ?? '',
            ...headers,
        };
    };

    return { fetch: doFetch };
};
