import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { loadFontAttributes } from 'design-system';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import MessageWithActions, { CONFIRMATION_DIALOG_LEVELS } from '../common/components/DS/MessagesWithActions';
import { useAddUsersToBadgerHeaderController } from './hooks/useAddUsersToBadgerHeaderController';

const StyledAppBar = styled(MuiAppBar)`
    background-color: ${({ theme }) => theme.palette.Background.bars.Color} !important;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    padding: 20px 0 !important;
    height: 110px !important;
    width: 100% !important;
    position: relative !important;
`;

const StyledToolbar = styled(Toolbar)`
    margin: 0 !important;
`;

const StyledTypography = styled(Typography)`
    width: 100% !important;
    padding: 4px 0 0 0 !important;
    /* Otter/XX-Large/light/center */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
`;

const CloseButton = styled(IconButton)`
    color: ${({ theme }) => theme.palette.Text.onButton.tertiary.enabled} !important;

    &.MuiIconButton-root {
        align-self: flex-start;
    }
`;

const AddUsersToBadgerHeader = () => {
    const { handleOnCancel, showUsersNotSavedWarning, setShowUsersNotSavedWarning } =
        useAddUsersToBadgerHeaderController();

    const renderUsersNotSavedWarning = () => (
        <MessageWithActions
            open={showUsersNotSavedWarning}
            severity={CONFIRMATION_DIALOG_LEVELS.WARNING}
            title={'Users not saved'}
            message={'Are you sure you want to close the window without saving the added users?'}
            cancelLabel={'Back to editing users'}
            confirmLabel={'Close without saving'}
            onCancel={() => setShowUsersNotSavedWarning(false)}
            onConfirm={() => handleOnCancel()}
        />
    );

    return (
        <>
            {showUsersNotSavedWarning && renderUsersNotSavedWarning()}
            <StyledAppBar elevation={0}>
                <StyledToolbar>
                    <StyledTypography variant="h6">Add users to Badger</StyledTypography>
                    <CloseButton aria-label="close-button" onClick={() => setShowUsersNotSavedWarning(true)}>
                        <CloseIcon />
                    </CloseButton>
                </StyledToolbar>
            </StyledAppBar>
        </>
    );
};

export default AddUsersToBadgerHeader;
