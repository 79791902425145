import Typography from '@mui/material/Typography';
import { Button, Dialog, Stack } from '@mui/material';
import ConnectionErrorIcon from './ConnectionErrorIcon.svg';
import React from 'react';
import styled from 'styled-components';
import { loadFontAttributes } from 'design-system';
import Box from '@mui/material/Box';

const StyledStack = styled(Stack)`
    justify-content: center;
    align-items: center;
    padding: 32px;
`;

const StyledTitle = styled(Typography)`
    /* Otter/XX-Large/medium/left */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.medium)};
`;

const StyledHint = styled(Typography)`
    /* Otter/XX-Large/light/center */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
    padding: 32px 0;
`;

const StyledButton = styled(Button)`
    display: flex;
    padding: 10px 20px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    background: ${({ theme }) => theme.palette.Background.button.primary.enabled} !important;

    /* Otter/typography_largeB */
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
    color: ${({ theme }) => theme.palette.Text.onButton.primary.enabled} !important;
    text-transform: none !important;
`;

const ConnectionErrorPopup = ({
    isOpen,
    handleTryAgainClick,
}: {
    isOpen: boolean;
    handleTryAgainClick?: () => void;
}) => {
    return (
        <Dialog open={isOpen} data-testid={'t-connection-error-popup'}>
            <StyledStack>
                <Box component="img" src={ConnectionErrorIcon} alt="Connection error icon" />
                <StyledTitle>Connection error</StyledTitle>
                <StyledHint>We suggest you check your internet connection and try again</StyledHint>
                <StyledButton onClick={handleTryAgainClick}>Try again</StyledButton>
            </StyledStack>
        </Dialog>
    );
};

export { ConnectionErrorPopup };
