import Company from '../../domain/Companies/Company';
import { CompaniesDataSource } from '../api/CompaniesDataSource';
import { CompanyToRequestDTOMapper } from './CompanyToRequestDTOMapper';

export type CompanyCreationRequestDTO = {
    name: string;
    csa?: {
        id: string;
    };
    isHipaa: boolean;
};

export const CompaniesRepository = () => {
    const { createCompany: createCompanyDS } = CompaniesDataSource();
    const { map } = CompanyToRequestDTOMapper();
    const createCompany = (company: Company) => {
        createCompanyDS(map(company));
    };

    return { createCompany };
};
