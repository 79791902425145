import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { GenericInputField } from 'design-system';
import styled from 'styled-components';

const StyledGenericInputField = styled(GenericInputField)<{ error: string }>`
    & .MuiOutlinedInput-root {
        width: 100%;
        height: 40px;
        margin: 8px 0;
        min-width: 140px;
        border-radius: 0;

        fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &:hover fieldset {
            ${({ theme, error }) => !error.length && `border-color: ${theme.palette.Border.fields.textField.filled};`}
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }
    }

    & .MuiInputLabel-outlined.Mui-focused {
        color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
    }
`;

export interface Props {
    disabled?: boolean;
    placeholder?: string;
    rules: RegisterOptions;
    control: Control;
    name: string;
    handleChange?: (value: string) => void;
}

const TextTypeField = ({ disabled, placeholder, rules, control, name, handleChange }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <StyledGenericInputField
                    value={field.value}
                    updateValue={(data) => {
                        field.onChange(data);
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const data = e.target.value;
                        if (handleChange) handleChange(data);
                    }}
                    placeholder={placeholder}
                    label=""
                    error={fieldState.invalid ? fieldState.error?.type || '' : ''}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default TextTypeField;
