import { useEffect, useState } from 'react';

const useVerticalScrollingListener = (scrollingEle: HTMLElement | null) => {
    const [scrollTop, SetScrollTop] = useState(0);

    useEffect(() => {
        if (scrollingEle) {
            scrollingEle.removeEventListener('scroll', handleScroll);
            scrollingEle.addEventListener('scroll', handleScroll, { passive: true });
            return () => scrollingEle.removeEventListener('scroll', handleScroll);
        }
    }, [scrollingEle]);

    const handleScroll = (event: Event) => {
        if (event.target) {
            SetScrollTop((event.target as HTMLElement).scrollTop);
        }
    };

    return {
        scrollTop,
    };
};

export default useVerticalScrollingListener;
