import { useErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';

const useShowErrorBoundaryOnPromiseRejections = () => {
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
            const error = event.reason;
            showBoundary(error);
        };

        window.addEventListener('unhandledrejection', unhandledRejectionHandler);

        return () => {
            window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
        };
    }, []);
};

export default useShowErrorBoundaryOnPromiseRejections;
