import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import CompanyDefinition from '../../../domain/CompanyDefinition/CompanyDefinition';
import { CompanyDefinitionAPI } from '../../api/companyDefinition/CompanyDefinitionAPI';

export const fetchCompanyDefinition = createAsyncThunk(
    'company/fetchCompanyDefinition',
    async (companyId: string, { getState }) => {
        const state = getState() as RootState;
        const { getCompanyDefinition } = CompanyDefinitionAPI();
        return getCompanyDefinition(companyId, state.userAuth.token);
    }
);

export const editCompanyDefinition = createAsyncThunk(
    'company/editCompanyDefinition',
    async (
        { companyId, companyDefinition }: { companyId: string; companyDefinition: CompanyDefinition },
        { getState }
    ) => {
        const state = getState() as RootState;
        const { editCompanyDefinition } = CompanyDefinitionAPI();
        return editCompanyDefinition(companyId, state.userAuth.token, companyDefinition);
    }
);
