import { CompanyCreationRequestDTO } from '../repositories/CompaniesRepository';

export const CompanyCreationRequestDTOMapper = () => {
    const map = (requestDto: CompanyCreationRequestDTO): object => {
        return {
            name: requestDto.name,
            is_hipaa: requestDto.isHipaa,
            ...(requestDto.csa && { csa: { id: requestDto.csa.id } }),
        };
    };

    return { map };
};
