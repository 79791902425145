import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useMultiUserSelectorControllerType } from './hooks/useMultiUserSelectorController';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button, { BUTTON_LEVELS, ButtonVariants } from '../../common/components/DS/Button';
import Box from '@mui/material/Box';
import { CrmUser } from '../../domain/Users/CrmUser';
import { Fragment } from 'react';
import { Dialog } from 'design-system';

export interface MultiUserSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    useController: useMultiUserSelectorControllerType;
}

const StyledList = styled(List)`
    &.MuiList-root {
        padding: 0;
    }
`;

const StyledListItem = styled(ListItem)`
    &.MuiListItem-root {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Label = styled.span`
    color: ${({ theme }) => theme.palette.Text.onButton.tertiary.direct_feedback};
    margin-right: 4px;
    ${({ theme }) => theme.typography.Large.bold};
`;

const SelectedContainer = styled(Box)`
    display: flex;
    align-items: center;
`;

const SelectedLabel = styled(Label)`
    color: ${({ theme }) => theme.palette.Text.onButton.tertiary.enabled};
`;

const UnselectButton = styled(CloseIcon)`
    color: ${({ theme }) => theme.palette.Text.onButton.tertiary.enabled};
`;

const MultiUserSelector = ({ visible, onConfirm, onClose, useController }: MultiUserSelectorProps) => {
    const { users, selectedCrmUsersIdsInSelector, loading, handleOnUserClick, handleOnConfirm } = useController();

    const renderIntegratedUser = () => (
        <Label data-testid="integrated-user-label">Already integrated with Badger</Label>
    );
    const renderSelectedUser = (crm_user_id: string) => (
        <SelectedContainer>
            <SelectedLabel>Selected</SelectedLabel>
            <IconButton data-testid="unselect-user-button" size="small" onClick={() => handleOnUserClick(crm_user_id)}>
                <UnselectButton />
            </IconButton>
        </SelectedContainer>
    );
    const renderSelectUser = (crm_user_id: string) => (
        <Button
            variant={ButtonVariants.CONTAINED}
            onClick={() => handleOnUserClick(crm_user_id)}
            severity={BUTTON_LEVELS.INFO}
        >
            <span data-testid="select-user-button">Select</span>
        </Button>
    );

    const renderAction = (user: CrmUser) => {
        if (user.isIntegrated) {
            return renderIntegratedUser();
        }
        if (selectedCrmUsersIdsInSelector.includes(user.crmUserId)) {
            return renderSelectedUser(user.crmUserId);
        }
        return renderSelectUser(user.crmUserId);
    };

    const content = (
        <StyledList>
            {users.map((user) => (
                <Fragment key={user.crmUserId}>
                    <StyledListItem secondaryAction={renderAction(user)}>
                        <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
                    </StyledListItem>
                    <Divider variant="fullWidth" component="li" />
                </Fragment>
            ))}
        </StyledList>
    );

    return (
        <Dialog
            title="List of CRM users"
            content={content}
            primaryButtonCaption={`Confirm ${selectedCrmUsersIdsInSelector.length} of users`}
            onPrimaryButtonClick={() => {
                handleOnConfirm();
                onConfirm();
            }}
            onClose={onClose}
            open={visible}
            loading={loading}
            primaryButtonDisabled={selectedCrmUsersIdsInSelector.length === 0 || loading}
        />
    );
};

export default MultiUserSelector;
