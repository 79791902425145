import styled from 'styled-components';
import { Alignments, Button, ButtonTypes, ExpandedMessage, Icon, loadFontAttributes } from 'design-system';
import { SyncDataIcons } from 'design-system';
import SyncDataProcessImage from './SyncDataProcessImage';
import { DataSync } from '../../domain/Crm/DataSync';
import { ImportStatus } from '../../domain/Records/Import';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ImportError } from '../../domain/Records/ImportError';

const StyledTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 13px;
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
`;

const styledText = styled.p`
    font-weight: 400;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
`;

const StyledTextProcess = styled(styledText)`
    font-size: 12px;
    margin-top: 32px;
    color: ${({ theme }) => theme.palette.Text.severity.info};
`;

const StyledContent = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.Background.secondary.default};
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
`;

const StyledBackground = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.Background.primary.default};
    width: 100%;
`;

export const SyncDataStatusPage = ({
    dataSync,
    importStatus,
    clearProcess,
    downloadImportErrors,
    importErrors,
    tryAgainTriggerSync,
    tryAgainTriggerImport,
}: {
    dataSync: DataSync;
    importStatus: ImportStatus | null;
    clearProcess: () => void;
    downloadImportErrors: () => void;
    importErrors: ImportError[] | null;
    tryAgainTriggerSync: () => void;
    tryAgainTriggerImport: () => void;
}) => {
    const buildTitle = () => {
        switch (dataSync.status) {
            case 'NOT_STARTED':
                return <StyledTitle> Retrieving data from CRM </StyledTitle>;
            case 'RUNNING':
                return <StyledTitle> Retrieving data from CRM </StyledTitle>;
            case 'VALIDATING':
                return <StyledTitle> Retrieving data from CRM </StyledTitle>;
            case 'ERROR':
                return <StyledTitle> Retrieving data from CRM failed </StyledTitle>;
        }

        switch (importStatus) {
            case null:
                return <StyledTitle> Importing data in Badger App </StyledTitle>;
            case 'NOT_STARTED':
                return <StyledTitle> Importing data in Badger App </StyledTitle>;
            case 'RUNNING':
                return <StyledTitle> Importing data in Badger App </StyledTitle>;
            case 'SUCCESS':
                if (importErrors === null) {
                    return <StyledTitle> Importing data in Badger App </StyledTitle>;
                }
                return <StyledTitle> Sync Data process completed! </StyledTitle>;
            case 'FAILED':
                return <StyledTitle> Importing Data in Badger App failed </StyledTitle>;
        }
    };

    const buildHelperText = () => {
        if (
            dataSync.status !== 'ERROR' &&
            importStatus !== 'FAILED' &&
            (importStatus !== 'SUCCESS' || importErrors === null)
        ) {
            return <StyledTextProcess> This process may take a while. </StyledTextProcess>;
        }
    };

    const buildErrorText = () => {
        if (importStatus === 'FAILED') {
            return 'Importing Data in Badger App failed';
        } else if (dataSync.status === 'ERROR') {
            return 'Retrieving data from CRM failed';
        }
    };

    const buildErrorPrompt = () => {
        const prompt = buildErrorText();
        if (prompt) {
            return (
                <Box minWidth={'fit-content'} width={'90%'} maxWidth={'500px'}>
                    <ExpandedMessage
                        type={'alert'}
                        title={prompt}
                        content={buildCrmError() || 'Please contact engineers.'}
                    />
                </Box>
            );
        }
    };

    const buildCrmError = () => {
        let crmError = dataSync?.errorMsg ? dataSync.errorMsg + '; ' : '';
        for (const bulkState of dataSync?.bulkStates ?? []) {
            if (bulkState.progress.errorMsg) {
                crmError += bulkState.progress.errorMsg + '; ';
            }
        }
        return crmError;
    };

    const buildDownloadReportButton = () => {
        if (importStatus === 'SUCCESS' && importErrors !== null) {
            return (
                <Box width={'fit-content'}>
                    <Button
                        type={ButtonTypes.TEXT}
                        onClick={downloadImportErrors}
                        icon={importErrors.length > 0 ? <Icon icon={SyncDataIcons.DOWNLOAD} /> : undefined}
                        alignment={'row' as Alignments}
                        disabled={importErrors.length === 0}
                    >
                        {importErrors.length > 0 ? (
                            <Typography>Download import Report</Typography>
                        ) : (
                            <Typography>No import errors found</Typography>
                        )}
                    </Button>
                </Box>
            );
        }
    };

    const buildGoBackButtonText = () => {
        if (dataSync.status === 'ERROR') {
            return 'Clear content and back to sync history';
        }
        if (importStatus === 'SUCCESS' && importErrors !== null) {
            return 'Back to Sync history';
        }
        if (importStatus === 'FAILED') {
            return 'Clear process and back to sync history';
        }
    };

    const buildGoBackButton = () => {
        const buttonText = buildGoBackButtonText();
        if (buttonText) {
            return (
                <Box>
                    <Button type={ButtonTypes.TEXT} onClick={clearProcess}>
                        {buttonText}
                    </Button>
                </Box>
            );
        }
    };

    const buildTryAgainButton = () => {
        if (dataSync.status === 'ERROR') {
            return (
                <Box>
                    <Button type={ButtonTypes.CONTAINED} onClick={tryAgainTriggerSync}>
                        Try again
                    </Button>
                </Box>
            );
        } else if (importStatus === 'FAILED') {
            return (
                <Box>
                    <Button type={ButtonTypes.CONTAINED} onClick={tryAgainTriggerImport}>
                        Try again
                    </Button>
                </Box>
            );
        } else if (importStatus === 'SUCCESS' && importErrors && importErrors.length > 0) {
            return (
                <Box>
                    <Button type={ButtonTypes.CONTAINED} onClick={tryAgainTriggerImport}>
                        Try again
                    </Button>
                </Box>
            );
        }
    };

    return (
        <StyledBackground justifyContent={'space-between'} height={'100%'} alignItems={'center'}>
            <StyledContent data-testid={'t-data-sync-status-page'} spacing={5}>
                {buildTitle()}
                <SyncDataProcessImage
                    data-testid={'test'}
                    dataSyncStatus={dataSync.status}
                    importStatus={importStatus}
                    importErrorsRetrieved={importErrors !== null}
                />
                {buildHelperText()}
                {buildErrorPrompt()}
                {buildDownloadReportButton()}
                {buildTryAgainButton()}
            </StyledContent>
            {buildGoBackButton()}
        </StyledBackground>
    );
};
