import { useState } from 'react';

export const useOnOff = () => {
    const [isOn, setIsOn] = useState(false);

    const setOff = () => setIsOn(false);
    const setOn = () => setIsOn(true);

    return {
        isOn,
        setOff,
        setOn,
    };
};
