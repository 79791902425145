import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadFontAttributes } from 'design-system';
import React from 'react';

export enum BUTTON_LEVELS {
    INFO = 'info',
    WARNING = 'warning',
}

export enum ButtonVariants {
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
}

export interface ButtonProps {
    variant?: ButtonVariants;
    disabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode[] | React.ReactNode | string;
    severity: BUTTON_LEVELS;
}

interface StyledButtonProps {
    variant?: ButtonVariants;
    severity: BUTTON_LEVELS;
}

const colorBySeverity = (severity: BUTTON_LEVELS, palette: any) => {
    switch (severity) {
        case BUTTON_LEVELS.INFO:
            return palette.Background.button.primary.enabled;
        case BUTTON_LEVELS.WARNING:
            return palette.Background.severity.warning_default;
        default:
            return palette.Background.button.primary.enabled;
    }
};

const colorFontBySeverity = (severity: BUTTON_LEVELS, palette: any) => {
    switch (severity) {
        case BUTTON_LEVELS.INFO:
            return palette.Text.onButton.primary.enabled;
        case BUTTON_LEVELS.WARNING:
            return palette.Text.onButton.secondary.enabled;
        default:
            return palette.Text.onButton.button.primary.enabled;
    }
};

const colorHoverBackgroundBySeverity = (severity: BUTTON_LEVELS, palette: any) => {
    switch (severity) {
        case BUTTON_LEVELS.INFO:
            return palette.Background.button.primary.hover;
        case BUTTON_LEVELS.WARNING:
            return palette.Background.severity.warning_hover;
        default:
            return palette.Background.button.primary.hover;
    }
};

const colorHoverFontBySeverity = (severity: BUTTON_LEVELS, palette: any) => {
    switch (severity) {
        case BUTTON_LEVELS.INFO:
            return palette.Text.onButton.primary.hover;
        case BUTTON_LEVELS.WARNING:
            return palette.Text.onButton.secondary.hover;
        default:
            return palette.Text.onButton.primary.hover;
    }
};

const handleStyle = (palette: any, typography: any, variant: ButtonVariants | undefined, severity: BUTTON_LEVELS) => {
    switch (variant) {
        case ButtonVariants.CONTAINED:
            return `
                background-color: ${colorBySeverity(severity, palette)} !important;
                color: ${colorFontBySeverity(severity, palette)};
                ${() => loadFontAttributes(typography.Large.bold.center)};
                `;
        case ButtonVariants.OUTLINED:
            return `
                background-color: ${palette.Basic.default_10};
                border: 1px solid ${colorBySeverity(severity, palette)};
                color: ${colorFontBySeverity(severity, palette)};
                ${() => loadFontAttributes(typography.Large.bold.center)};
                `;
        default:
            return `
                background-color: ${palette.Background.button.primary.enabled};
                color: ${palette.Text.onButton.primary.enabled}`;
    }
};

const handleOnHoverStyle = (palette: any, severity: BUTTON_LEVELS) => {
    return `
        background-color: ${colorHoverBackgroundBySeverity(severity, palette)};
        color: ${colorHoverFontBySeverity(severity, palette)};`;
};

const handleOnActiveStyle = (palette: any) => `background-color: ${palette.Background.button.primary.active}`;

const handleOnDisabledStyle = (palette: any) => `
    background-color: ${palette.Background.button.primary.disabled} !important;
    color: ${palette.Text.onButton.primary.disabled} !important;`;
const StyledButton = styled.button<StyledButtonProps>`
    display: flex;
    justify-content: center;
    min-width: 126px;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    text-align: center;
    border: none;
    border-radius: 5px;
    align-items: center;
    ${(props) => handleStyle(props.theme.palette, props.theme.typography, props.variant, props.severity)};

    &:hover {
        cursor: pointer;
        ${(props) => handleOnHoverStyle(props.theme.palette, props.severity)};
    }

    &:active {
        ${(props) => handleOnActiveStyle(props.theme.palette)};
    }

    &:disabled {
        cursor: default;
        ${(props) => handleOnDisabledStyle(props.theme.palette)};
    }
`;

const Button = ({ variant, disabled, children, severity, onClick }: ButtonProps) => (
    <StyledButton onClick={onClick} disabled={disabled} variant={variant} severity={severity}>
        {children}
    </StyledButton>
);

Button.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired,
    ]).isRequired,
    severity: PropTypes.oneOf(Object.values(BUTTON_LEVELS)).isRequired,
};

Button.defaultProps = {
    disabled: false,
    onClick: () => {},
};

export default Button;
