import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

const usePageLayoutWithMenuController = () => {
    const theme = useTheme();
    const shouldHideMenu = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(!shouldHideMenu);

    const handleDrawerOpen = () => setOpen(true);

    const handleDrawerClose = () => setOpen(false);

    return {
        open,
        handleDrawerOpen,
        handleDrawerClose,
    };
};

export { usePageLayoutWithMenuController };

export type usePageLayoutWithMenuControllerType = typeof usePageLayoutWithMenuController;
