import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { DataSync } from '../../../domain/Crm/DataSync';

type Body = {
    id: string;
    company_id: string;
    import_id: string;
    requester_user_id: string;
    status: string;
    bulk_states: {
        object_type: string;
        bulk_id: string;
        progress: {
            status: string;
            error_msg: string;
        };
    }[];
    error_msg: string;
};

export const DataSyncApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    function transform(body: Body): DataSync {
        return {
            id: body.id,
            companyId: body.company_id,
            importId: body.import_id,
            requesterUserId: body.requester_user_id,
            status: body.status,
            bulkStates: body.bulk_states.map((state) => ({
                objectType: state.object_type,
                bulkId: state.bulk_id,
                progress: {
                    status: state.progress.status,
                    errorMsg: state.progress.error_msg,
                },
            })),
            errorMsg: body.error_msg,
        } as DataSync;
    }

    const getCurrent = async (companyId: string) => {
        try {
            const response = await fetch(
                HttpMethod.GET,
                `/api/3/crm/companies/${companyId}/data-syncs/current/status`,
                userAuth.token
            );
            return transform(response.body);
        } catch (error) {
            return null;
        }
    };

    return {
        getCurrent,
    };
};
