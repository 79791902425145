import useUserAuth from '../common/hooks/useUserAuth';
import { useNavigate } from 'react-router-dom';

export const useLoginController = () => {
    const { setUsername, setPassword, getToken, userAuth } = useUserAuth();
    const navigate = useNavigate();

    return {
        setUsername,
        setPassword,
        getToken,
        userAuth,
        navigate,
    };
};
