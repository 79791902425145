import { UserCreationResult, UserCreationStatus, ValidationError } from '../../../domain/Users/UserCreationResult';

const useCompanyCrmUserCreationValidationMapper = () => {
    const fromAPI = (body: any): UserCreationResult[] => {
        const results = body.results;
        return results.map(_map_crm_user);
    };

    const _map_crm_user = (user: any): UserCreationResult => ({
        errors: _map_user_validation_errors(user.errors),
        status: _convertUserCreationStatus(user.status),
        userId: user.user_id,
    });

    const _map_user_validation_errors = (errors: any): ValidationError => {
        const validationError: any = {};

        if (errors !== null) {
            if (errors.email) {
                validationError.email = errors.email;
            }

            if (errors.username) {
                validationError.username = errors.username;
            }

            return validationError;
        }
        return {};
    };

    const _convertUserCreationStatus = (userStatus: string): UserCreationStatus => {
        switch (userStatus) {
            case 'SUCCEEDED':
                return UserCreationStatus.SUCCEEDED;
            case 'FAILED':
                return UserCreationStatus.FAILED;
            default:
                throw new Error(`Invalid user status: ${userStatus}`);
        }
    };

    return { fromAPI };
};

export { useCompanyCrmUserCreationValidationMapper };
