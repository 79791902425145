import { SvgIcon } from '@mui/material';
import { ReactComponent as GreenCircleIcon } from './green_circle.svg';
import { ReactComponent as RedCircleIcon } from './red_circle.svg';

export const ACTIVE_COMPOANY_ICON_TITLE = 'active-company';
export const INACTIVE_COMPOANY_ICON_TITLE = 'inactive-company';

export const ActiveCompanyIcon = () => <SvgIcon title={ACTIVE_COMPOANY_ICON_TITLE} component={GreenCircleIcon} />;

export const InactiveCompanyIcon = () => <SvgIcon title={INACTIVE_COMPOANY_ICON_TITLE} component={RedCircleIcon} />;
