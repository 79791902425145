import styled from 'styled-components';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Box, Grid, Stack } from '@mui/material';
import { Icon, IconNames, loadFontAttributes } from 'design-system';
import { CompanyDefinitionHeaderControllerType } from './companyDefinitionHeaderController';

const StyledAppBar = styled(MuiAppBar)`
    background-color: ${({ theme }) => theme.palette.Background.bars.Color} !important;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    position: relative !important;
    height: 100%;
`;

const StyledGrid = styled(Grid)`
    padding: 10px 30px;
    height: 100%;
`;

const Title = styled(Typography)`
    text-align: center;
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const StyledPath = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    cursor: pointer;
`;

const StyledCurrentPath = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const StyledData = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const ToolsContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
`;

const StyledIcon = styled(Icon)`
    cursor: pointer;
    color: ${({ theme }) => theme.palette.O_Primary.default};
`;

const CompanyDefinitionHeader = ({ useController }: { useController: CompanyDefinitionHeaderControllerType }) => {
    const { selectedCompanyName, goToCompanies, goBackToUsers } = useController();

    return (
        <StyledAppBar elevation={0}>
            <StyledGrid container>
                <Grid item xs={4}>
                    <Stack direction={'row'} spacing={1}>
                        <StyledPath onClick={goToCompanies}>Companies /</StyledPath>
                        <StyledPath onClick={goBackToUsers}>{selectedCompanyName} /</StyledPath>
                        <StyledCurrentPath>Field settings</StyledCurrentPath>
                    </Stack>
                </Grid>
                <Grid item container xs={4} alignItems={'center'} justifyContent={'center'}>
                    <Stack spacing={1}>
                        <Title>Field settings</Title>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                            <StyledData>{selectedCompanyName}</StyledData>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <ToolsContainer>
                        <StyledIcon icon={IconNames.CLOSE} width={24} onClick={goBackToUsers} aria-label="close-icon" />
                    </ToolsContainer>
                </Grid>
            </StyledGrid>
        </StyledAppBar>
    );
};

export default CompanyDefinitionHeader;
