import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import { LinkOff } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Button, { BUTTON_LEVELS, ButtonVariants } from './Button';
import { Dialog, DialogContentText, Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { loadFontAttributes } from 'design-system';

export enum CONFIRMATION_DIALOG_LEVELS {
    INFO = 'info',
    WARNING = 'warning',
}

export interface MessageWithActionsProps {
    open: boolean;
    title: string;
    severity: CONFIRMATION_DIALOG_LEVELS;
    message: string;
    cancelLabel: string;
    confirmLabel: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const StyledLinkOffIcon = styled(LinkOff)`
    background-color: #f1d44a;
    color: ${({ theme }) => theme.palette.Text.severity.warningDark};
    width: 49px !important;
    height: 49px !important;
    border-radius: 100%;
    padding: 11px !important;

    & .MuiSvgIcon-root {
        font-size: 40px !important;
    }
`;

const iconBySeverity = (severity: CONFIRMATION_DIALOG_LEVELS) => {
    switch (severity) {
        case CONFIRMATION_DIALOG_LEVELS.INFO:
            return <InfoIcon />;
        case CONFIRMATION_DIALOG_LEVELS.WARNING:
            return <StyledLinkOffIcon />;
        default:
            return <InfoIcon />;
    }
};

const colorBySeverity = (severity: CONFIRMATION_DIALOG_LEVELS) => {
    switch (severity) {
        case CONFIRMATION_DIALOG_LEVELS.INFO:
            return '#2F5B8F';
        case CONFIRMATION_DIALOG_LEVELS.WARNING:
            return '#F1D44A';
        default:
            return '#2F5B8F';
    }
};

const StyledDialogContainer = styled(Dialog)`
    display: flex;
    justify-content: center;
    align-items: center;

    & .MuiDialog-container {
        width: 400px !important;
    }

    & .MuiPaper-root {
        margin: 0 !important;
    }
`;

const Action = styled.div`
    padding: 0;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    padding: 20px;
    box-shadow: inset 0px 2px 0px -1px #eeeeee;
`;

const StyledDialogContentText = styled(DialogContentText)`
    color: #1b2845 !important;
    /* Otter/Medium/regular */
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)}
    padding: 20px;
`;

const Title = styled(Typography)`
    color: #1b2845;
    text-align: center;
    /* Otter/X-Large/semibold */
    ${({ theme }) => loadFontAttributes(theme.typography.X_Large.semibold)};
`;

interface ContainerProps {
    severity: CONFIRMATION_DIALOG_LEVELS;
}

const Header = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    box-shadow: inset 0px 7px 0px -4px ${(props) => colorBySeverity(props.severity)};
    padding: 12px;
`;

const StyledIcon = styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
    height: fit-content !important;
`;

const MessageWithActions = ({
    open,
    title,
    severity,
    message,
    cancelLabel,
    confirmLabel,
    onCancel,
    onConfirm,
}: MessageWithActionsProps) => {
    const icon = iconBySeverity(severity);
    return (
        <StyledDialogContainer open={open}>
            <Header severity={severity} aria-label="message-with-actions-header">
                <StyledIcon>{icon}</StyledIcon>
                <Title>{title}</Title>
            </Header>
            <StyledDialogContentText>{message}</StyledDialogContentText>
            <ActionsContainer>
                <Action>
                    <Button variant={ButtonVariants.OUTLINED} onClick={onCancel} severity={BUTTON_LEVELS.WARNING}>
                        {cancelLabel}
                    </Button>
                </Action>
                <Action>
                    <Button variant={ButtonVariants.CONTAINED} onClick={onConfirm} severity={BUTTON_LEVELS.WARNING}>
                        {confirmLabel}
                    </Button>
                </Action>
            </ActionsContainer>
        </StyledDialogContainer>
    );
};

MessageWithActions.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(Object.values(CONFIRMATION_DIALOG_LEVELS)),
    message: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    confirmLabel: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default MessageWithActions;
