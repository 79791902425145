import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import { ObjectType } from '../../../domain/Crm/ObjectType';

export const CompanyCRMObjectTypesAPI = () => {
    return {
        getAll: async (companyId: string, token: string): Promise<ObjectType[]> => {
            const result = await HttpClient().fetch(
                HttpMethod.GET,
                `/api/3/crm/companies/${companyId}/object-types`,
                token
            );
            if (result.status !== 200) {
                throw result.status;
            }
            return result.body.results.map((result: { label: string; name: string }) => result as ObjectType);
        },
    };
};
