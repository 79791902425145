import { useEffect, useState } from 'react';
import { RecordDefinition } from '../../../domain/Records/RecordDefinition';
import { RecordDefinitionsRepository } from '../repositories/RecordDefinitionsRepository';
import { useCompanyUsersRepository } from '../../../common/hooks/useCompanyUsersRepository';
import { DataSyncService } from '../services/DataSyncService';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';
import { DataSyncRepository } from '../repositories/DataSyncRepository';
import { DataSync } from '../../../domain/Crm/DataSync';
import { Import } from '../../../domain/Records/Import';
import { ImportRepository } from '../repositories/ImportRepository';
import { ImportErrorsDownloader } from './ImportErrorsDownloader';
import { ImportError } from '../../../domain/Records/ImportError';
import { ImportErrorsRepository } from '../repositories/ImportErrorsRepository';

const POLLING_INTERVAL_SECONDS = 30;

const useCompanySyncDataMainContentController = () => {
    const { getRecordDefinitions } = RecordDefinitionsRepository();
    const { users } = useCompanyUsersRepository();
    const { selectedCompanyId } = useSelectCompany();
    const [showSelectObjectsModal, setShowSelectObjectsModal] = useState(false);
    const [chosenRecordDefinitions, setChosenRecordDefinitions] = useState<RecordDefinition[]>([]);
    const [availableRecordDefinitions, setAvailableRecordDefinitions] = useState<RecordDefinition[]>([]);
    const { resetProcess } = DataSyncService();

    const dataSyncRepository = DataSyncRepository();
    const [currentDataSync, setCurrentDataSync] = useState<DataSync | null>(null);

    const importRepository = ImportRepository();
    const [currentImport, setCurrentImport] = useState<Import | null>(null);

    const { triggerDataSync } = DataSyncService();
    const { tryAgainDataSync } = DataSyncService();

    const importErrorsRepository = ImportErrorsRepository();
    const { triggerImportErrorsDownloadForUser } = ImportErrorsDownloader();

    const [importErrors, setImportErrors] = useState<ImportError[] | null>(null);

    const triggerSync = async () => {
        if (selectedCompanyId) {
            setShowSelectObjectsModal(false);
            const dataSync = await triggerDataSync(selectedCompanyId, chosenRecordDefinitions);
            setCurrentDataSync(dataSync);
        }
    };

    const tryAgainTriggerSync = async () => {
        if (selectedCompanyId) {
            const currentDataSync = await dataSyncRepository.getCurrent(selectedCompanyId);
            if (currentDataSync !== null) {
                const dataSync = await tryAgainDataSync(selectedCompanyId, currentDataSync);
                setCurrentDataSync(dataSync);
            }
        }
    };

    const tryAgainTriggerImport = async () => {
        if (selectedCompanyId && currentImport) {
            const importData = await importRepository.post(selectedCompanyId, currentImport, 'S3');
            setCurrentImport(importData);
        }
    };

    useEffect(() => {
        const doGetRecordDefinitions = async () => {
            if (users.length !== 0 && users[0].id) {
                const recordDefinitions = await getRecordDefinitions(users[0].id);
                setAvailableRecordDefinitions(recordDefinitions);
            }
        };
        doGetRecordDefinitions().then((r) => console.log('Record Definitions fetched'));
    }, [users]);

    const updateCurrentDataSync = async () => {
        if (selectedCompanyId) {
            const dataSync = await dataSyncRepository.getCurrent(selectedCompanyId);
            setCurrentDataSync(dataSync);
            if (dataSync && dataSync.importId) {
                const importData = await importRepository.get(dataSync.importId);
                setCurrentImport(importData);
                if (importData?.status === 'SUCCESS' && importErrors === null) {
                    const errors = await importErrorsRepository.getImportErrors(importData.id);
                    setImportErrors(errors);
                }
            } else {
                setCurrentImport(null);
            }
        }
    };

    useEffect(() => {
        updateCurrentDataSync();
        const interval = setInterval(() => {
            updateCurrentDataSync();
        }, 1000 * POLLING_INTERVAL_SECONDS);
        return () => clearInterval(interval);
    }, [users]);

    const clearProcess = async () => {
        if (selectedCompanyId) {
            await resetProcess(selectedCompanyId);
        }
        updateCurrentDataSync();
    };

    const downloadImportErrors = () => {
        if (currentImport?.id && importErrors) {
            triggerImportErrorsDownloadForUser(currentImport.id, importErrors);
        }
    };

    return {
        showSelectObjectsModal,
        setShowSelectObjectsModal,
        chosenRecordDefinitions,
        setChosenRecordDefinitions,
        availableRecordDefinitions,
        triggerSync,
        currentDataSync,
        currentImport,
        clearProcess,
        downloadImportErrors,
        importErrors,
        tryAgainTriggerSync,
        tryAgainTriggerImport,
    };
};

export { useCompanySyncDataMainContentController };

export type useCompanySyncDataMainContentControllerType = typeof useCompanySyncDataMainContentController;
