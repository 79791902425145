import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import { mapFromAPI, mapToAPI } from './companyDefinitionMapper';
import CompanyDefinition from '../../../domain/CompanyDefinition/CompanyDefinition';

export const CompanyDefinitionAPI = () => {
    const getCompanyDefinition = async (companyId: string, token: string | undefined) => {
        const { fetch } = HttpClient();
        const path = `/api/3/records/companies/${companyId}/company-definition`;
        const { body } = await fetch(HttpMethod.GET, path, token);
        return mapFromAPI(body);
    };

    const editCompanyDefinition = async (
        companyId: string,
        token: string | undefined,
        companyDefinition: CompanyDefinition
    ) => {
        const { fetch } = HttpClient();
        const path = `/api/3/records/companies/${companyId}/company-definition`;
        const rawCompanyDefinition = mapToAPI(companyDefinition);
        const { body } = await fetch(HttpMethod.PUT, path, token, rawCompanyDefinition);
        return mapFromAPI(body);
    };

    return { getCompanyDefinition, editCompanyDefinition };
};
