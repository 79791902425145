import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';

export const DataSyncResetApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    const resetProcess = async (companyId: string) => {
        try {
            await fetch(HttpMethod.PUT, `/api/3/crm/companies/${companyId}/data-syncs/current/reset`, userAuth.token);
        } catch {
            console.log('An error occurred when resetting the data sync process');
        }
    };

    return {
        resetProcess,
    };
};
