import { Dialog, DropdownOption, GenericInputField, InputWithDropdown } from 'design-system';
import { FormControlLabel, Stack, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { useCreateCompanyModalControllerType } from './hooks/useCreateCompanyModalController';
import { User } from '../domain/Users/User';

const ControlsContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 83px;
    padding-bottom: 83px;
`;

const ComponentsBox = styled(Box)`
    width: 263px;
`;

const InputsBoxes = styled(Box)`
    margin-bottom: 16px;
`;

const StyledStack = styled(Stack)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 12px;
`;

export const CreateCompanyModal = ({
    onClose,
    useController,
    isOpen = false,
}: {
    onClose: () => void;
    useController: useCreateCompanyModalControllerType;
    isOpen?: boolean;
}) => {
    const { companyName, setCompanyName, setIsHipaa, isHipaa, staff, setStaff, availableStaff, createCompany } =
        useController();

    const createDropdownOptions = (users: User[]): DropdownOption[] => {
        return users.map(
            (user): DropdownOption => ({
                value: user,
                label: user.firstName + ' ' + user.lastName,
            })
        );
    };

    const handleOnPrimaryButtonClick = () => {
        createCompany(companyName, staff, isHipaa);
        onClose();
    };

    return (
        <Dialog
            title={'Create a new company'}
            primaryButtonCaption={'Create company'}
            onPrimaryButtonClick={handleOnPrimaryButtonClick}
            primaryButtonDisabled={companyName === ''}
            secondaryButtonCaption={"Don't create"}
            onSecondaryButtonClick={onClose}
            open={isOpen}
            onClose={onClose}
            content={
                <ControlsContainer>
                    <ComponentsBox>
                        <form method="post" action="">
                            <InputsBoxes>
                                <GenericInputField
                                    value={companyName}
                                    updateValue={setCompanyName}
                                    label="Company name"
                                    placeholder="Company name"
                                    isRequired={true}
                                />
                            </InputsBoxes>
                            <InputsBoxes>
                                <InputWithDropdown
                                    updateValue={setStaff}
                                    label="POC for this company"
                                    options={createDropdownOptions(availableStaff)}
                                    suggestedOptionsLimit={3}
                                />
                            </InputsBoxes>

                            <StyledStack direction="row">
                                <Typography>HIPAA:</Typography>
                                <RadioGroup
                                    row
                                    value={isHipaa ? 'true' : 'false'}
                                    onChange={(e) => setIsHipaa(e.target.value === 'true')}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value={'false'} control={<Radio />} label="No" />
                                    <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
                                </RadioGroup>
                            </StyledStack>
                        </form>
                    </ComponentsBox>
                </ControlsContainer>
            }
        />
    );
};
