import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Alignments, Button, ButtonIcons, Icon } from 'design-system';
import styled from 'styled-components';

const StyledContainer = styled(Stack)`
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

export const NoObjectDefinitionsContent = ({
    openObjectTypeSelectionModal,
}: {
    openObjectTypeSelectionModal: () => void;
}) => {
    return (
        <StyledContainer gap={4} data-testid={'t-no-object-definitions-content'}>
            <Typography>There are no retrieved objects</Typography>
            <div>
                <Button
                    onClick={openObjectTypeSelectionModal}
                    icon={<Icon icon={ButtonIcons.ADD} width={14} height={14} />}
                    alignment={Alignments.ROW}
                >
                    Retrieve Object from CRM
                </Button>
            </div>
        </StyledContainer>
    );
};
