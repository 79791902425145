import { useState } from 'react';

export type MenuItemData = {
    title: string;
    path: string;
};

const MENU_ITEMS: MenuItemData[] = [{ title: 'Companies', path: '/companies' }];

const useSelectedItem = () => {
    const [selectedItem, setSelectedItem] = useState<MenuItemData | undefined>(undefined);
    return { selectedItem, setSelectedItem };
};

export { useSelectedItem, MENU_ITEMS };
