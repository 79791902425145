import { DataSyncTriggerApiConnector } from '../api/DataSyncTriggerApiConnector';
import { DataSyncResetApiConnector } from '../api/DataSyncResetApiConnector';

export const DataSyncService = () => {
    const { triggerDataSync } = DataSyncTriggerApiConnector();
    const { tryAgainDataSync } = DataSyncTriggerApiConnector();
    const { resetProcess } = DataSyncResetApiConnector();
    return {
        triggerDataSync,
        resetProcess,
        tryAgainDataSync,
    };
};
