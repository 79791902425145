import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateSelectedCompanyId, selectSelectedCompanyId } from '../../store/slices/companiesSlice';

const useSelectCompany = () => {
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

    const selectCompany = (companyId: string) => {
        dispatch(updateSelectedCompanyId(companyId));
    };

    return { selectedCompanyId, selectCompany };
};

export { useSelectCompany };
