import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { selectIsLoading, selectUserCreationResults } from '../../store/slices/companyCrmUsersSlice';
import { createCompanyCrmUsers } from '../../store/middleware/companyCrmUsers/companyCrmUsersThunks';
import { useSelectDraftUsers } from '../../common/hooks/useSelectDraftUsers';

const useCreateCompanyCrmUsersRepository = () => {
    const { selectedCompanyId: companyId } = useSelectCompany();
    const dispatch = useAppDispatch();
    const userCreationResults = useAppSelector(selectUserCreationResults);
    const loading = useAppSelector(selectIsLoading);
    const { draftUsers } = useSelectDraftUsers();

    const dispatchCreateCompanyCrmUsers = async () => {
        if (companyId) {
            const result = await dispatch(createCompanyCrmUsers({ companyId, draftUsers }));
            return result.payload;
        }
    };

    return { userCreationResults, loading, dispatchCreateCompanyCrmUsers };
};

export { useCreateCompanyCrmUsersRepository };
