import { selectDraftUsers, updateDraftUsers } from '../../store/slices/companyCrmUsersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DraftUser } from '../../domain/Users/DraftUser';

const useSelectDraftUsers = () => {
    const dispatch = useDispatch();
    const draftUsers = useSelector(selectDraftUsers);

    const selectDraftUsersToUpdate = (newDraftUsers: DraftUser[]) => {
        dispatch(updateDraftUsers(newDraftUsers));
    };

    return { draftUsers, selectDraftUsersToUpdate };
};

export { useSelectDraftUsers };
