import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import CompanyDefinition from '../../../domain/CompanyDefinition/CompanyDefinition';
import { editCompanyDefinition, fetchCompanyDefinition } from './thunks';

interface CompanyState {
    companyDefinition: CompanyDefinition | null;
    isLoading: boolean;
    isSaving: boolean;
}

const initialState: CompanyState = {
    companyDefinition: null,
    isLoading: false,
    isSaving: false,
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyDefinition.fulfilled, (state, action) => {
            state.companyDefinition = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchCompanyDefinition.rejected, (state) => {
            state.companyDefinition = null;
            state.isLoading = false;
        });
        builder.addCase(fetchCompanyDefinition.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editCompanyDefinition.pending, (state) => {
            state.isSaving = true;
        });
        builder.addCase(editCompanyDefinition.fulfilled, (state, action) => {
            state.companyDefinition = action.payload;
            state.isSaving = false;
        });
        builder.addCase(editCompanyDefinition.rejected, (state) => {
            state.companyDefinition = null;
            state.isSaving = false;
        });
    },
});

export const selectCompanyDefinition = (state: RootState): CompanyDefinition | null => {
    return state.company.companyDefinition;
};

export const selectIsSaving = (state: RootState): boolean => {
    return state.company.isSaving;
};

export default companySlice.reducer;
