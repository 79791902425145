import CssBaseline from '@mui/material/CssBaseline';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { persistor, store } from './store/store';
import { Theme } from 'design-system';

ReactDOM.render(
    <React.StrictMode>
        <Theme name="OtterTheme">
            <CssBaseline />
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </Theme>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
);
