import Page from '../common/components/Page';
import CompanyDefinitionContent from './CompanyDefinitionContent/CompanyDefinitionContent';
import { CompanyDefinitionContentControllerType } from './CompanyDefinitionContent/companyDefinitionContentController';
import CompanyDefinitionHeader from './CompanyDefinitionHeader/CompanyDefinitionHeader';
import { CompanyDefinitionHeaderControllerType } from './CompanyDefinitionHeader/companyDefinitionHeaderController';
import { CompanyDefinitionPageControllerType } from './companyDefinitionPageController';
import { TabsPanelControllerType } from './CompanyDefinitionContent/TabsPanel/tabsPanelController';

const CompanyDefinitionPage = ({
    useController,
    useCompanyDefinitionHeaderController,
    useCompanyDefinitionContentController,
    useTabsPanelController,
}: {
    useController: CompanyDefinitionPageControllerType;
    useCompanyDefinitionHeaderController: CompanyDefinitionHeaderControllerType;
    useCompanyDefinitionContentController: CompanyDefinitionContentControllerType;
    useTabsPanelController: TabsPanelControllerType;
}) => {
    useController();

    return (
        <Page>
            <Page.Header>
                <CompanyDefinitionHeader useController={useCompanyDefinitionHeaderController} />
            </Page.Header>
            <Page.Content>
                <CompanyDefinitionContent
                    useController={useCompanyDefinitionContentController}
                    useTabsPanelController={useTabsPanelController}
                />
            </Page.Content>
        </Page>
    );
};

export default CompanyDefinitionPage;
