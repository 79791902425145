import Page from '../common/components/Page';
import AddUsersToBadgerHeader from './AddUsersToBadgerHeader';
import AddUsersToBadgerMainContent from './AddUsersToBadgerMainContent';

const AddUsersToBadgerPage = () => (
    <Page>
        <Page.Header>
            <AddUsersToBadgerHeader aria-label="add-users-to-badger-header" />
        </Page.Header>
        <Page.Content>
            <AddUsersToBadgerMainContent aria-label="add-users-to-badger-main-content" />
        </Page.Content>
    </Page>
);

export default AddUsersToBadgerPage;
