enum UserStatus {
    NOT_CONNECTED = 'NOT_CONNECTED',
    COMPANY_SETUP_USER = 'COMPANY_SETUP_USER',
    INACTIVE = 'INACTIVE',
    CREATE_INSTANCE = 'CREATE_INSTANCE',
    SYNCING = 'SYNCING',
    CONNECTED = 'CONNECTED',
}

enum UserState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

type User = {
    id?: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    dateJoined?: string;
    state?: UserState;
};

export type { User };
export { UserStatus, UserState };
