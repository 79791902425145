import { useMemo, useState } from 'react';
import { buildColumns, CONDITIONAL_COLUMNS } from '../columns';

const useGetVisibleColumns = () => {
    const columns: any[] = useMemo(buildColumns, []);
    const [visibleColumns, setVisibleColumns] = useState<any[]>([]);

    const updateVisibleColumns = (getConditionalColumns: boolean) => {
        const conditionalColumns = columns.filter((column) => CONDITIONAL_COLUMNS.includes(column.name));
        conditionalColumns.forEach((column) => (column.visible = getConditionalColumns));
        const updatedColumns = columns.filter((column) => column.visible);
        setVisibleColumns(updatedColumns);
        return updatedColumns;
    };

    return { columns: visibleColumns, updateVisibleColumns };
};

export default useGetVisibleColumns;
