import ObjectDefinitionTable from '../ObjectDefinitionsTable/ObjectDefinitionsTable';
import { ObjectDefinitionsTableControllerType } from '../ObjectDefinitionsTable/objectDefinitionsTableController';
import { ObjectDefinitionContentControllerType } from './objectDefinitionContentController';
import { styled } from 'styled-components';
import { Button, ChildrenContainer, ConfirmationDialog, loadFontAttributes, SEVERITY_LEVELS } from 'design-system';
import ObjectParameters from '../ObjectParameters/ObjectParameters';
import { useObjectParametersController } from '../ObjectParameters/objectParametersController';

const ActionBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    gap: 20px;
    border-top: 2px solid ${({ theme }) => theme.palette.Basic.lighter};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    ${ChildrenContainer} {
        width: 125px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 85px);
    align-items: center;
    padding: 5px 15px 0 15px;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    overflow-y: auto;
    overflow-x: hidden;
`;

const Title = styled.h2`
    margin: 0;
    padding: 20px;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    ${({ theme }) => loadFontAttributes(theme.typography.X_Large.semibold)};
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 300px;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const ObjectParametersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 45px);
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ConfirmationDialogContainer = styled.div`
    z-index: 9999 !important;
`;

interface props {
    useController: ObjectDefinitionContentControllerType;
    objectDefinitionTableController: ObjectDefinitionsTableControllerType;
}

const ObjectDefinitionContent = ({ useController, objectDefinitionTableController }: props) => {
    const {
        columns,
        rows,
        control,
        getValues,
        isDirty,
        isValid,
        savingChanges,
        saveChanges,
        confirmationDialogOpen,
        closeConfirmationDialog,
        resetState,
        discardChangesFromRouteBlockerPopup,
        addField,
        deleteField,
        reorder,
        fields,
        handleInputChange,
        handleMultiPicklistChange,
        scrollingContainerRef,
        fieldsParametersTitleRef,
        tableContainerRef,
        tableScrollOffset,
    } = useController();

    return (
        <MainContainer>
            <Container ref={scrollingContainerRef} data-testid="scrolling-container">
                <StyledForm style={{}}>
                    <TableContainer ref={tableContainerRef}>
                        <Title ref={fieldsParametersTitleRef}>Fields parameters</Title>
                        <ObjectDefinitionTable
                            columns={columns}
                            rows={rows}
                            addField={addField}
                            deleteField={deleteField}
                            reorder={reorder}
                            formControl={control}
                            useController={objectDefinitionTableController}
                            handleInputChange={handleInputChange}
                            scrollOffset={tableScrollOffset}
                        />
                    </TableContainer>
                    <ObjectParametersContainer>
                        <Title>Object parameters</Title>
                        <ObjectParameters
                            fields={fields}
                            formControl={control}
                            getValues={getValues}
                            useController={useObjectParametersController}
                            handleInputChange={handleInputChange}
                            handleMultiPicklistChange={handleMultiPicklistChange}
                        />
                    </ObjectParametersContainer>
                </StyledForm>
            </Container>

            <ActionBar>
                <ButtonContainer>
                    <Button disabled={!isDirty || savingChanges} onClick={resetState}>
                        Discard changes
                    </Button>
                </ButtonContainer>

                <ButtonContainer>
                    <Button disabled={!isDirty || savingChanges || !isValid} onClick={saveChanges}>
                        {savingChanges ? 'Saving changes...' : 'Save changes'}
                    </Button>
                </ButtonContainer>
            </ActionBar>
            {confirmationDialogOpen && (
                <ConfirmationDialogContainer>
                    <ConfirmationDialog
                        title="Unsaved changes"
                        confirmLabel="Save changes"
                        cancelLabel="Discard changes"
                        onConfirm={saveChanges}
                        confirmDisabled={!isDirty || savingChanges || !isValid}
                        onCancel={discardChangesFromRouteBlockerPopup}
                        onClose={closeConfirmationDialog}
                        severity={SEVERITY_LEVELS.WARNING}
                    >
                        Are you sure you want to leave this window without saving changes?
                    </ConfirmationDialog>
                </ConfirmationDialogContainer>
            )}
        </MainContainer>
    );
};

export default ObjectDefinitionContent;
