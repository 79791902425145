import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSelectedCompanyCrmUsers, updateSelectedCrmUserIds } from '../../store/slices/companyCrmUsersSlice';

const useSelectSelectedUsers = () => {
    const dispatch = useAppDispatch();
    const selectedCrmUserIds = useAppSelector(selectSelectedCompanyCrmUsers);

    const selectSelectedCrmUserIds = (selectedUsers: string[]) => {
        dispatch(updateSelectedCrmUserIds(selectedUsers));
    };

    return { selectedCrmUserIds, selectSelectedCrmUserIds };
};

export { useSelectSelectedUsers };
