import { Outlet } from 'react-router-dom';
import TabsPanel from './TabsPanel/TabsPanel';
import { TabsPanelControllerType } from './TabsPanel/tabsPanelController';
import { CompanyDefinitionContentControllerType } from './companyDefinitionContentController';
import { styled } from 'styled-components';
import { NoObjectDefinitionsContent } from './NoObjectDefinitionsContent/NoObjectDefinitionsContent';
import { useOnOff } from '../../common/hooks/useOnOff';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CompanyDefinitionContent = ({
    useController,
    useTabsPanelController,
}: {
    useController: CompanyDefinitionContentControllerType;
    useTabsPanelController: TabsPanelControllerType;
}) => {
    const { tabs } = useController();
    const modalDisplayControl = useOnOff();

    return (
        <>
            {tabs.length > 0 ? (
                <Container>
                    <TabsPanel tabs={tabs} useController={useTabsPanelController} />
                    <Outlet />
                </Container>
            ) : (
                <NoObjectDefinitionsContent openObjectTypeSelectionModal={modalDisplayControl.setOn} />
            )}
            {modalDisplayControl.isOn && <div>Modal</div>}
        </>
    );
};

export default CompanyDefinitionContent;
