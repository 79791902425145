import { StaffDTO } from './AvailableStaffRepository';
import { User, UserState, UserStatus } from '../../domain/Users/User';

export const StaffDtoToUserMapper = () => {
    const map = (staff: StaffDTO): User => {
        const mappedUser: User = {
            id: staff.id,
            firstName: staff.first_name,
            lastName: staff.last_name,
            username: staff.username,
            email: staff.email,
        };
        if (staff.date_joined) {
            mappedUser.dateJoined = staff.date_joined;
        }
        if (staff.state) {
            mappedUser.state = staff.state as UserState;
        }
        return mappedUser;
    };

    return { map };
};
