import styled from 'styled-components';
import Box from '@mui/material/Box';
import SyncStages from './assets/sync_data_stages_overview.svg';
import { Button, loadFontAttributes } from 'design-system';
import { useCompanySyncDataMainContentControllerType } from './hooks/useCompanySyncDataMainContentController';
import { ChooseDataTypeModal } from './ChooseDataTypeModal';
import { SyncDataStatusPage } from './SyncDataStatusPage';

const BackgroundStyleBox = styled(Box)`
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    width: 100%;
    height: 100%;
`;

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    flex-flow: column;
    height: 100%;
`;

const StyledImg = styled(Box)`
    width: 294px;
    height: 167.94px;
`;

const StyledTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 13px;
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
`;

const StyledSubHeader = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
`;

const styledText = styled.p`
    font-weight: 400;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
`;

const StyledTextOverview = styled(styledText)`
    font-size: 14px;
    margin-bottom: 32px;
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
`;

const CompanySyncDataMainContent = ({
    useCompanySyncDataMainContentController,
}: {
    useCompanySyncDataMainContentController: useCompanySyncDataMainContentControllerType;
}) => {
    const {
        showSelectObjectsModal,
        setShowSelectObjectsModal,
        availableRecordDefinitions,
        triggerSync,
        chosenRecordDefinitions,
        setChosenRecordDefinitions,
        currentDataSync,
        currentImport,
        clearProcess,
        downloadImportErrors,
        importErrors,
        tryAgainTriggerSync,
        tryAgainTriggerImport,
    } = useCompanySyncDataMainContentController();

    return (
        <BackgroundStyleBox>
            <StyledBox>
                {currentDataSync ? (
                    <SyncDataStatusPage
                        dataSync={currentDataSync}
                        importStatus={currentImport?.status ?? null}
                        clearProcess={clearProcess}
                        downloadImportErrors={downloadImportErrors}
                        importErrors={importErrors}
                        tryAgainTriggerSync={tryAgainTriggerSync}
                        tryAgainTriggerImport={tryAgainTriggerImport}
                    />
                ) : (
                    <>
                        <StyledTitle>Welcome to the Sync Data tool</StyledTitle>
                        <StyledSubHeader>The Sync Data process has the two following stages</StyledSubHeader>
                        <StyledImg component="img" alt="Sync Stages" src={SyncStages} />
                        <StyledTextOverview> Let's get started!</StyledTextOverview>
                        <Box>
                            <Button onClick={() => setShowSelectObjectsModal(true)}>Retrieve data from CRM</Button>
                        </Box>
                        <ChooseDataTypeModal
                            availableRecordDefinitions={availableRecordDefinitions}
                            isOpen={showSelectObjectsModal}
                            onClose={() => setShowSelectObjectsModal(false)}
                            onChoose={triggerSync}
                            chosenRecordDefinitions={chosenRecordDefinitions}
                            setChosenRecordDefinitions={setChosenRecordDefinitions}
                        />
                    </>
                )}
            </StyledBox>
        </BackgroundStyleBox>
    );
};

export default CompanySyncDataMainContent;
