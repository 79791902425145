import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyCrmUsersAPI } from '../../api/companyCrmUsers/CompanyCrmUsersAPI';
import { RootState } from '../../store';
import { DraftUser } from '../../../domain/Users/DraftUser';
import { UserCreationResult } from '../../../domain/Users/UserCreationResult';

const fetchCompanyCrmUsers = createAsyncThunk(
    'companyCrmUsers/fetchCompanyCrmUsers',
    async (companyId: string | undefined, { getState, rejectWithValue }) => {
        const { getAllForCompany } = CompanyCrmUsersAPI();
        const state = getState() as RootState;
        return getAllForCompany(companyId, state.userAuth.token);
    }
);

type CreateCompanyCrmUsersThunkArgs = {
    companyId: string;
    draftUsers: DraftUser[];
};

const createCompanyCrmUsers = createAsyncThunk<UserCreationResult[], CreateCompanyCrmUsersThunkArgs>(
    'companyCrmUsers/createCompanyCrmUsers',
    async ({ companyId, draftUsers }, { getState, rejectWithValue }) => {
        const { createUsers } = CompanyCrmUsersAPI();
        const state = getState() as RootState;
        return createUsers(companyId, state.userAuth.token, draftUsers);
    }
);

export { fetchCompanyCrmUsers, createCompanyCrmUsers };
export type { CreateCompanyCrmUsersThunkArgs };
