import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { Import } from '../../../domain/Records/Import';

type Body = {
    id: string;
    created_at: string;
    finished_at: string;
    total_time_taken_milliseconds: number;
    objects_synced: string[];
    status: 'NOT_STARTED' | 'RUNNING' | 'SUCCESS' | 'FAILED';
    url: string;
};

export const ImportApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    const transformBody = (body: Body): Import => {
        return {
            id: body.id,
            createdAt: body.created_at,
            finishedAt: body.finished_at,
            totalTimeTakenMilliseconds: body.total_time_taken_milliseconds,
            objectsSynced: body.objects_synced,
            status: body.status,
            url: body.url,
        };
    };

    const createPostBody = (company_id: string, currentImport: Import, import_type: string) => {
        return {
            company_id,
            url: currentImport.url,
            type: import_type,
        };
    };

    const get = async (import_id: string) => {
        try {
            const response = await fetch(HttpMethod.GET, `/api/3/imports/${import_id}/status`, userAuth.token);
            return transformBody(response.body as Body);
        } catch (error) {
            return null;
        }
    };

    const post = async (company_id: string, currentImport: Import, importType: string) => {
        try {
            const postBody = createPostBody(company_id, currentImport, importType);
            const response = await fetch(HttpMethod.POST, `/api/3/imports`, userAuth.token, postBody);
            return transformBody(response.body as Body);
        } catch (error) {
            return null;
        }
    };

    return {
        get,
        post,
    };
};
