import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useCompanyUsersRepository } from '../../common/hooks/useCompanyUsersRepository';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useCompanyControlPanelController = () => {
    const { selectedCompanyId } = useSelectCompany();
    const { users } = useCompanyUsersRepository();
    const location = useLocation();
    const navigate = useNavigate();

    const pathLastElement = location.pathname.split('/').slice(-1)[0];

    const segmentsDataWithPathIdentifier = [
        {
            label: `Users (${users.length})`,
            pathIdentifier: 'users',
        },
        {
            label: 'Sync Data',
            pathIdentifier: 'sync-data',
        },
    ];

    const [selectedSegment, setSelectedSegment] = useState(pathLastElement);

    useEffect(() => {
        setSelectedSegment(pathLastElement);
    }, [pathLastElement]);

    const onSegmentSelection = (segment: string) => {
        setSelectedSegment(segment);
        navigate(`/companies/${selectedCompanyId}/${segment}`);
    };

    return {
        selectedSegment,
        onSegmentSelection,
        segmentsDataWithPathIdentifier,
    };
};

export default useCompanyControlPanelController;

export type useCompanyControlPanelControllerType = typeof useCompanyControlPanelController;
