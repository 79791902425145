import Page from '../common/components/Page';
import CompaniesMainContent from './CompaniesMainContent';
import CompaniesHeader from './CompaniesHeader';
import { useCompaniesMainContentControllerType } from './hooks/useCompaniesMainContentController';
import { useCreateCompanyModalControllerType } from './hooks/useCreateCompanyModalController';

const CompaniesPage = ({
    useCompaniesMainContentController,
    useCreateCompanyModalController,
}: {
    useCompaniesMainContentController: useCompaniesMainContentControllerType;
    useCreateCompanyModalController: useCreateCompanyModalControllerType;
}) => {
    return (
        <Page>
            <Page.Header>
                <CompaniesHeader data-testid="companies-header" />
            </Page.Header>
            <Page.Content>
                <CompaniesMainContent
                    data-testid="companies-main-content"
                    useCompaniesMainContentController={useCompaniesMainContentController}
                    useCreateCompanyModalController={useCreateCompanyModalController}
                />
            </Page.Content>
        </Page>
    );
};

export default CompaniesPage;
