import { useEffect } from 'react';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCompanyCrmUsers, selectIsLoading } from '../../../store/slices/companyCrmUsersSlice';
import { fetchCompanyCrmUsers } from '../../../store/middleware/companyCrmUsers/companyCrmUsersThunks';

const useCompanyCrmUsersRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectCompanyCrmUsers);
    const loading = useAppSelector(selectIsLoading);

    useEffect(() => {
        if (selectedCompanyId) {
            dispatch(fetchCompanyCrmUsers(selectedCompanyId));
        }
    }, [selectedCompanyId]);

    return { users, loading };
};

export { useCompanyCrmUsersRepository };
