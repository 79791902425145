import { createListenerMiddleware, isRejected } from '@reduxjs/toolkit';
import { HTTP_401_ERROR } from '../../common/api/HttpClient';
import { logOut } from '../slices/userAuthSlice';

export const logOutOn401 = createListenerMiddleware();

logOutOn401.startListening({
    predicate: (action) => isRejected(action) && action.error.message === HTTP_401_ERROR.toString(),
    effect: (_, listenerApi) => {
        listenerApi.dispatch(logOut());
    },
});
