import { useCompaniesRepository } from './useCompaniesRepository';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const useCompaniesMainContentController = () => {
    const navigate = useNavigate();
    const { companies } = useCompaniesRepository();
    const { selectCompany } = useSelectCompany();
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);

    const handleOnCompanyClick = (companyId: string) => {
        selectCompany(companyId);
        navigate(`/companies/${companyId}/users`);
    };

    return {
        companies,
        handleOnCompanyClick,
        showAddCompanyModal,
        setShowAddCompanyModal,
    };
};

export { useCompaniesMainContentController };

export type useCompaniesMainContentControllerType = typeof useCompaniesMainContentController;
