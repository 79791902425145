import { useState } from 'react';
import { useGetToken } from './useGetToken';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUserAuth, updateUserAuth } from '../../store/slices/userAuthSlice';

export default function useUserAuth() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { getTokenFromAPI } = useGetToken();
    const dispatch = useAppDispatch();
    const userAuth = useAppSelector(selectUserAuth);

    const getToken = async () => {
        const userInformation = await getTokenFromAPI(username, password);
        dispatch(updateUserAuth(userInformation));
    };

    return { getToken, setUsername, setPassword, userAuth };
}
