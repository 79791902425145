import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';

export const RecordDefinitionsMapper = () => {
    const map = (recordDefinition: any) => {
        return {
            object_type: recordDefinition.name_plural,
            label: recordDefinition.label_plural,
        };
    };
    return {
        map,
    };
};

export const RecordDefinitionsApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();
    const { map } = RecordDefinitionsMapper();

    const fetchRecordDefinitions = async (user_id: string) => {
        try {
            const response = await fetch(HttpMethod.GET, `/api/3/users/${user_id}/record-definitions`, userAuth.token);
            return response.body.records.map(map);
        } catch {
            return [];
        }
    };

    return {
        fetchRecordDefinitions,
    };
};
