import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

export interface props {
    control: any;
    name: string;
    placeholder?: string;
    rules: any;
    disabled: boolean;
    handleChange?: (value: number) => void;
}

const NumericField = ({ control, name, placeholder = '', rules, disabled, handleChange }: props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
                return field.value !== undefined ? (
                    <TextField
                        type="number"
                        value={field.value}
                        placeholder={placeholder}
                        disabled={disabled}
                        error={fieldState.invalid}
                        onChange={(data) => {
                            field.onChange(data);
                            if (handleChange) handleChange(Number(data.target.value));
                        }}
                        inputProps={{
                            min: rules.min,
                            max: rules.max,
                            'data-testid': `${name}-input`,
                        }}
                    />
                ) : (
                    <></>
                );
            }}
        />
    );
};

export default NumericField;
