import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';

export interface TabDetails {
    label: string;
    pathIdentifier: string;
}

export const useTabsPanelController = (tabs: TabDetails[]) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedCompanyId } = useSelectCompany();

    const pathLastElement = location.pathname.split('/').slice(-1)[0];

    const [selectedSegment, setSelectedSegment] = useState(pathLastElement);

    useEffect(() => {
        setSelectedSegment(pathLastElement);
    }, [tabs, pathLastElement]);

    const onSegmentSelection = (segment: string) => {
        setSelectedSegment(segment);
        navigate(`/companies/${selectedCompanyId}/field-settings/${segment}`);
    };

    return {
        selectedSegment,
        onSegmentSelection,
    };
};

export type TabsPanelControllerType = typeof useTabsPanelController;
