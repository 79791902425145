import Box from '@mui/material/Box';
import { FormGroup, Checkbox, FormControlLabel } from '@mui/material';
import { Dialog, loadFontAttributes } from 'design-system';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { RecordDefinition } from '../../domain/Records/RecordDefinition';

const StyledTypography = styled(Typography)`
    padding: 20px;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
`;

const StyledFormGroup = styled(FormGroup)`
    justify-content: center;
    align-content: center;
    row-gap: -2px;
    padding: 10px 20px 20px 20px;

    .MuiFormControlLabel-root {
        height: 30px;
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiTypography-root {
        ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)} !important;
    }
`;

export const ChooseDataTypeModal = ({
    availableRecordDefinitions,
    isOpen,
    onClose,
    onChoose,
    chosenRecordDefinitions,
    setChosenRecordDefinitions,
}: {
    availableRecordDefinitions: RecordDefinition[];
    isOpen: boolean;
    onClose: () => void;
    onChoose: () => void;
    chosenRecordDefinitions: RecordDefinition[];
    setChosenRecordDefinitions: (recordDefinitions: RecordDefinition[]) => void;
}) => {
    const _handleAllCheckboxesChange = () => {
        if (_areAllChosen()) {
            setChosenRecordDefinitions([]);
        } else {
            setChosenRecordDefinitions([...availableRecordDefinitions]);
        }
    };
    const _areAllChosen = () => chosenRecordDefinitions.length === availableRecordDefinitions.length;

    const _areNoneChosen = () => chosenRecordDefinitions.length === 0;

    const _handleCheckboxChange = (recordDefinition: RecordDefinition) => {
        if (_isChosen(recordDefinition)) {
            _discard(recordDefinition);
        } else {
            _choose(recordDefinition);
        }
    };

    const _isChosen = (recordDefinition: RecordDefinition) =>
        chosenRecordDefinitions.some((r) => r.object_type === recordDefinition.object_type);

    const _choose = (recordDefinition: RecordDefinition) => {
        setChosenRecordDefinitions([...chosenRecordDefinitions, recordDefinition]);
    };

    const _discard = (recordDefinition: RecordDefinition) => {
        setChosenRecordDefinitions(
            chosenRecordDefinitions.filter((r) => r.object_type !== recordDefinition.object_type)
        );
    };

    const _renderContent = () => (
        <Box>
            <StyledTypography align={'center'}>
                Choose the Data types you would like to retrieve from the CRM:
            </StyledTypography>
            {availableRecordDefinitions.length !== 0 ? (
                <StyledFormGroup>
                    <StyledFormControlLabel
                        control={<Checkbox />}
                        label="Select All Data"
                        onChange={_handleAllCheckboxesChange}
                        checked={_areAllChosen()}
                    />
                    {availableRecordDefinitions.map((recordDefinition) => (
                        <StyledFormControlLabel
                            key={recordDefinition.object_type}
                            control={
                                <Checkbox
                                    checked={_isChosen(recordDefinition)}
                                    onChange={() => _handleCheckboxChange(recordDefinition)}
                                />
                            }
                            label={recordDefinition.label}
                        />
                    ))}
                </StyledFormGroup>
            ) : (
                <StyledTypography align={'center'}>No data available for syncing for this company.</StyledTypography>
            )}
        </Box>
    );

    return (
        <Dialog
            title="Choose data type"
            open={isOpen}
            onClose={onClose}
            primaryButtonCaption={'Choose selection'}
            onPrimaryButtonClick={onChoose}
            content={_renderContent()}
            secondaryButtonCaption={'Go back'}
            onSecondaryButtonClick={onClose}
            primaryButtonDisabled={_areNoneChosen()}
        />
    );
};
