import Page from '../common/components/Page';
import CompanyHeader from './CompanyHeader';
import { Outlet } from 'react-router-dom';
import CompanyControlPanel from './CompanyControlPanel';
import styled from 'styled-components';
import { useCompanyHeaderControllerType } from './CompanyUsersPage/hooks/useCompanyHeaderController';
import { useCompanyPageControllerType } from './hooks/useCompanyPageController';
import { useCompanyControlPanelControllerType } from './hooks/useCompanyControlPanelController';
import { Stack } from '@mui/material';

const Content = styled(Stack)`
    height: 100%;
`;

const CompanyPage = ({
    useController,
    useCompanyHeaderController,
    useCompanyControlPanelController,
}: {
    useController: useCompanyPageControllerType;
    useCompanyHeaderController: useCompanyHeaderControllerType;
    useCompanyControlPanelController: useCompanyControlPanelControllerType;
}) => {
    const { useUpdateSelectedCompanyIdToMatchURL } = useController();
    useUpdateSelectedCompanyIdToMatchURL();

    return (
        <Page>
            <Page.Header>
                <CompanyHeader useController={useCompanyHeaderController} />
            </Page.Header>
            <Page.Content>
                <Content data-testid={'t-content-with-navbar'}>
                    <CompanyControlPanel useController={useCompanyControlPanelController} />
                    <Outlet />
                </Content>
            </Page.Content>
        </Page>
    );
};

export default CompanyPage;
