import { ImportApiConnector } from '../api/ImportApiConnector';

export const ImportRepository = () => {
    const { get } = ImportApiConnector();
    const { post } = ImportApiConnector();
    return {
        get,
        post,
    };
};
