import { useEffect } from 'react';
import { fetchCompanies, selectCompanies } from '../../store/slices/companiesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useCompaniesRepository = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(selectCompanies);

    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);

    return { companies };
};
