import { ImportErrorsApiConnector } from '../api/ImportErrorsApiConnector';

export const ImportErrorsRepository = () => {
    const { getImportErrors } = ImportErrorsApiConnector();
    return {
        getImportErrors: async (importId: string) => {
            return getImportErrors(importId);
        },
    };
};
