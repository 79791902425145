import { useState } from 'react';
import { Row, ROW_COLUMN_SEPARATOR } from '../defs';
import { DropResult } from 'react-beautiful-dnd';

export const useObjectDefinitionsTableController = (reorder: (from: number, to: number) => void) => {
    const [pickListValuesEditorVisible, setPickListValuesEditorVisible] = useState(false);
    const [picklistValueFormName, setPicklistValueFormName] = useState<string>('');
    const [picklistFieldName, setPicklistFieldName] = useState<string>('');
    const [picklistFieldUuid, setPicklistFieldUuid] = useState<string>('');

    const handleOnPicklistClick = (event: React.MouseEvent<HTMLElement>, row: Row, column: any) => {
        setPicklistValueFormName(`${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`);
        setPicklistFieldName(row.name);
        setPicklistFieldUuid(row.uuid);
        setPickListValuesEditorVisible(true);
    };

    return {
        handleOnPicklistClick,
        picklistFieldUuid,
        picklistFieldName,
        picklistValueFormName,
        pickListValuesEditorVisible,
        closePickListValuesEditor: () => setPickListValuesEditorVisible(false),
        onDragEnd: (result: DropResult) => {
            reorder(result.source.index, result.destination?.index || 0);
        },
    };
};

export type ObjectDefinitionsTableControllerType = typeof useObjectDefinitionsTableController;
