import { AvailableStaffDataSource } from '../api/AvailableStaffDataSource';
import { StaffDtoToUserMapper } from './StaffDtoToUserMapper';
import { User } from '../../domain/Users/User';

export type StaffDTO = {
    id: string;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    last_log_in?: string;
    date_joined?: string;
    state?: string;
};

export const AvailableStaffRepository = () => {
    const { getAvailableStaff } = AvailableStaffDataSource();
    const { map: mapStaff } = StaffDtoToUserMapper();

    const mapMultipleUsers = (staff: StaffDTO[]): User[] => {
        const mapped = staff.map((s) => mapStaff(s));
        return mapped;
    };

    const doGetAvailableStaff = async () => {
        try {
            const availableStaff = await getAvailableStaff();
            return mapMultipleUsers(availableStaff);
        } catch (error) {
            console.log(error);
            throw new Error('Failed to retrieve available staff');
        }
    };

    return { getAvailableStaff: doGetAvailableStaff };
};
