import { ImportError } from '../../../domain/Records/ImportError';

export const ImportErrorsDownloader = () => {
    const triggerImportErrorsDownloadForUser = async (importId: string, errors: ImportError[]) => {
        const blob = new Blob([JSON.stringify(errors)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = `import_errors_${importId}.json`;
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return { triggerImportErrorsDownloadForUser };
};
