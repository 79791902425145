import { ErrorIcons, GeneralError } from 'design-system';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export interface Props {
    message: string;
}

const AppError = ({ message }: Props) => {
    return (
        <Container>
            <GeneralError
                icon={ErrorIcons.APP_ERROR}
                title="There was an error"
                description={message}
                subtitle="If the problem persist, please contact us"
            />
        </Container>
    );
};

export default AppError;
