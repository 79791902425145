import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import React, { ReactNode } from 'react';
import AccessForbiddenComponent from './AccessForbiddenComponent/AccessForbiddenComponent';
import { HTTP_403_ERROR } from '../../api/HttpClient';
import { ConnectionErrorPopup } from './ConnectionError/ConnectionErrorPopup';
import AppError from './AppError/AppError';

type ErrorBoundaryComponentProps = {
    children: ReactNode;
};

const fallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
    switch (error.message) {
        case HTTP_403_ERROR.toString():
            return <AccessForbiddenComponent />;
        case 'Failed to fetch':
            return <ConnectionErrorPopup isOpen={true} handleTryAgainClick={resetErrorBoundary} />;
        default:
            return <AppError message={error.message} />;
    }
};

const ErrorBoundaryComponent: React.FC<ErrorBoundaryComponentProps> = ({ children }) => {
    return (
        <ErrorBoundary
            onError={(error, info) => {
                console.log(error, info);
            }}
            fallbackRender={fallbackRender}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryComponent;
