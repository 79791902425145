import Company, { State } from '../../../domain/Companies/Company';

const fromAPI = (body: any): Company[] => {
    const items = body.items;
    return items.map(_map_company);
};

const _map_company = (company: any): Company => ({
    id: company.id.toString(),
    csa: company.csa,
    name: company.name,
    numberOfActiveUsers: company.number_of_active_users,
    state: company.state === 'ACTIVE' ? State.ACTIVE : State.INACTIVE,
    crm: company.crm,
    isHipaa: company.is_hipaa,
});

const useCompaniesMapper = () => ({ fromAPI });

export { useCompaniesMapper };
