import { SegmentedControls } from 'design-system';
import { TabDetails, TabsPanelControllerType } from './tabsPanelController';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 30px;
`;

interface props {
    tabs: TabDetails[];
    useController: TabsPanelControllerType;
}

const TabsPanel = ({ tabs, useController }: props) => {
    const { selectedSegment, onSegmentSelection } = useController(tabs);

    return (
        <Container>
            <SegmentedControls
                selectedSegment={selectedSegment}
                onChange={onSegmentSelection}
                segmentsData={tabs.map((segment: TabDetails) => ({
                    label: segment.label,
                    value: segment.pathIdentifier,
                }))}
                variant="standard"
                size="large"
            />
        </Container>
    );
};

export default TabsPanel;
