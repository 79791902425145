import { useNavigate } from 'react-router-dom';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';
import { useSelectedCompanyName } from '../../../common/hooks/useSelectedCompanyName';

export const useCompanyHeaderController = () => {
    const { selectedCompanyName } = useSelectedCompanyName();
    const { selectedCompanyId } = useSelectCompany();
    const navigate = useNavigate();
    const goBackToCompanies = () => navigate('/companies');
    const goToFieldSettings = () => navigate(`/companies/${selectedCompanyId}/field-settings`);

    return { selectedCompanyName, selectedCompanyId, goBackToCompanies, goToFieldSettings };
};

export type useCompanyHeaderControllerType = typeof useCompanyHeaderController;
