import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { loadFontAttributes } from 'design-system';

export const StyledDataGrid = styled(DataGrid)`
    padding: 0 30px;
    padding-bottom: 30px;
    border: none !important;
    height: calc(100% - 60px) !important;

    & .MuiDataGrid-columnHeaders {
        background-color: ${({ theme }) => theme.palette.Background.table.header.default};
    }

    & .MuiDataGrid-columnHeaderTitle {
        ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)}
    }

    & .MuiDataGrid-virtualScroller {
        background-color: white;
        height: fit-content;
    }

    & .MuiDataGrid-footerContainer {
        display: none;
    }

    & .MuiDataGrid-row:hover {
        background-color: ${({ theme }) => theme.palette.Background.table.content.hover} !important;
        cursor: pointer;
    }

    & .MuiDataGrid-cell {
        outline: none !important;
    }
`;
