import { User, UserState, UserStatus } from '../../../domain/Users/User';

const useCompanyUsersMapper = () => {
    const fromAPI = (body: any): User[] => {
        const items = body?.items || [];
        return items.map(_map_user);
    };

    const _map_user = (user: any): User => ({
        id: user.id,
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        dateJoined: _mapDate(user.date_joined),
        state: _mapState(user.state),
    });

    const _mapDate = (date: string): string => {
        return new Date(Date.parse(date)).toDateString();
    };

    const _mapState = (state: string): UserState => {
        switch (state) {
            case 'ACTIVE':
                return UserState.ACTIVE;
            case 'INACTIVE':
                return UserState.INACTIVE;
            default:
                throw new Error('Invalid user state');
        }
    };

    return { fromAPI };
};

export { useCompanyUsersMapper };
