import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { loadFontAttributes } from 'design-system';

const StyledAppBar = styled(MuiAppBar)`
    background-color: ${({ theme }) => theme.palette.Background.bars.Color} !important;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    height: 110px !important;
    width: 100% !important;
    position: relative !important;
`;

const StyledToolbar = styled(Toolbar)`
    margin: auto 0 !important;
`;

const StyledTypography = styled(Typography)`
    width: 100% !important;
    text-align: center;
    /* Otter/XX-Large/light/center */
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
`;

const CompaniesHeader = (props: { 'data-testid'?: string }) => (
    <StyledAppBar {...props} elevation={0}>
        <StyledToolbar>
            <StyledTypography variant="h6" noWrap>
                Companies
            </StyledTypography>
        </StyledToolbar>
    </StyledAppBar>
);

export default CompaniesHeader;
