import { useCallback, useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

export interface ConfirmationProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export interface Props {
    isDirty?: boolean;
    subtitle?: string;
    openConfirmationDialog: (props: ConfirmationProps) => void;
}

export const useUnsavedChangesPrompt = ({
    openConfirmationDialog,
    subtitle = 'you have unsaved changes, are you sure you want to leave?',
    isDirty = false,
}: Props) => {
    const blocker = useBlocker(isDirty);

    const confirm = useCallback(() => {
        if (!isDirty) return Promise.resolve(true);
        return new Promise<boolean>((resolve) =>
            openConfirmationDialog({
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
            })
        );
    }, [isDirty, openConfirmationDialog]);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            confirm().then((result) => {
                if (result) blocker.proceed();
                else blocker.reset();
            });
        }
    }, [blocker, confirm]);

    useEffect(() => {
        if (isDirty) {
            window.onbeforeunload = () => subtitle;
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty, subtitle]);

    return {
        confirm,
    };
};
